import React, { useEffect, useState } from 'react'
import { Button, Container, Form, Table } from 'react-bootstrap'
import { LogicTable } from './LogicTable'
import { useTranslation } from 'react-i18next';

const to_number=(stri)=>{
    if (typeof stri=='string'){
        return Number(stri.replace(",", "."))

    }
    else{
        return Number(stri)

    } 

    
}

export const Logic = React.forwardRef(({selectedUses,selectedComps,selectedConsti,restElements,manualComps,fullList,finalConst,setFinalConst,updateFormData,selectedTrans,finalTrans,setFinalTrans,transparency,fullListComps,tresh,selectedMine,selectedComer},ref) => {
    const { t, i18n } = useTranslation("newformulas");
    
    // useEffect(() => {console.log('constituyentes',selectedConsti)},[selectedConsti])
    // useEffect(() => {console.log('uses',selectedUses)},[selectedUses])
    // useEffect(() => {console.log('comps',selectedComps)},[selectedComps])
    // useEffect(() => {console.log('restElements',restElements)},[restElements])
    // useEffect(() => {console.log('manual comps',manualComps)},[manualComps])




    const search_min_cat = (element) => {
        const posibleMins=selectedUses.map(use=>{return to_number(element[use.category])})
        const min = Math.min(...posibleMins);
        const index = posibleMins.indexOf(min);
        const category = (index === -1 ? "See Notebox" : selectedUses[index].category)  
        // return Math.min(...posibleMins)
        return {min:(index === -1 ? 'See Notebox' : min),category:category}
    }

    useEffect(()=>{
       
            //va a ser una array de elementos ifra
        //creo un middleman
        let middleman=selectedConsti.map(element=>{return {ifraElement:element.ifraElement,procedence:'Element',fullPercent:to_number(element.percent)*to_number(element.intensity)/10000}})
        //appendeo de comp
       
        if(fullList.length==0){
            // console.log('no lo hagas')
        }
        else{
            // console.log('ahora si')
            for (var compound of selectedComps){
                const fullPercento=to_number(compound.percent)*to_number(compound.intensity)/10000
                
                middleman=middleman.concat(compound.ifraElement.composition.map(innerConst => {
                    const indexOfpoint=innerConst.cas_constituent.indexOf(';')
                    const preprocesCas=innerConst.cas_constituent.substr(0, indexOfpoint) 
                    // aca podria existir un mini bug
                    // const indexOfpoint2=innerConst.cas_constituent.indexOf(';')
                    // const preprocesCas2=innerConst.other_CAS.substr(0, indexOfpoint2) 
                    let fullListo= fullList.find(x => x.cas_number.toLowerCase().includes((indexOfpoint===-1 ? innerConst.cas_constituent:preprocesCas)))
                    if (fullListo==null){
                        
                        // fullListo= fullList.find(x => x.cas_number.toLowerCase().includes((indexOfpoint2===-1 ? innerConst.other_CAS:preprocesCas2) ))
                       // if (fullListo==null){
                        //console.log('fullList',fullList)
                        //console.log('ultra not found', innerConst) //"Cassia oil" "Cinnamon bark extract" "Cinnamon bark oil" "Cinnamon leaf oil" "Hyacinth absolute" "Laurel leaf oil"
                       // }
                    }
                    
                    return({
                        ifraElement:fullListo,
                        procedence:innerConst.ncs_name,
                        fullPercent:to_number(innerConst.concen_constituent)*fullPercento/100
    
                    })
                }
                ))
                
            }


            for (var compound of manualComps){
            const fullPercento=to_number(compound.percent)/100
            const proc=compound.name
            let toCat=[]
            let nonIfra=[]

            for (var innerConst of compound.composition){
                if (innerConst.type=='consti'){
                    const preprocesCas=innerConst.cas
                    let fullListo= fullList.find(x => x.cas_number.toLowerCase()==preprocesCas)
                    toCat.push({
                        ifraElement:fullListo,
                        procedence:proc,
                        fullPercent:to_number(innerConst.percent)*fullPercento/100
    
                    })

                }
                else if (innerConst.type=='complex'){
                    const compSquare=fullListComps.find(x=>x.CAS==innerConst.cas)
                    const fullPercento2=(to_number(innerConst.percent)*fullPercento)/100
                    

                    middleman=middleman.concat(compSquare.composition.map(l => {
                        const indexOfpoint=l.cas_constituent.indexOf(';')
                        const preprocesCas=l.cas_constituent.substr(0, indexOfpoint) 
                        // aca podria existir un mini bug
                        // const indexOfpoint2=innerConst.cas_constituent.indexOf(';')
                        // const preprocesCas2=innerConst.other_CAS.substr(0, indexOfpoint2) 
                        let o= fullList.find(x => x.cas_number.toLowerCase().includes((indexOfpoint===-1 ? l.cas_constituent:preprocesCas)))
                        if (o==null){
                            
                            // fullListo= fullList.find(x => x.cas_number.toLowerCase().includes((indexOfpoint2===-1 ? innerConst.other_CAS:preprocesCas2) ))
                            // if (fullListo==null){
                            console.log('ultra not found') //"Cassia oil" "Cinnamon bark extract" "Cinnamon bark oil" "Cinnamon leaf oil" "Hyacinth absolute" "Laurel leaf oil"
                            // }
                        }
                        
                        return({
                            ifraElement:o,
                            procedence:`${proc} (${compSquare.ncs_name})`,
                            fullPercent:to_number(l.concen_constituent)*fullPercento2/100
        
                        })
                    }
                    ))
                    
                }



                

            
                
                
               
                
               
            }
        
            middleman=middleman.concat(toCat)

            
        }

        // meto las local
        // console.log('ceviene',selectedMine)
        // console.log(selectedMine)
        // console.log(selectedMine.length)
        
        try {
            for (var loc of selectedMine){
                const procer=loc.ifraElement.name
                const perc=to_number(loc.percent)*to_number(loc.intensity)/10000
                let toCat=[]
                for (var innerConst of loc.ifraElement.finalConst){
                    toCat.push({
                        ifraElement:innerConst.ifraElement,
                        procedence:procer,
                        fullPercent:innerConst.fullPercent*perc
    
                    })
    
                }
                middleman=middleman.concat(toCat)
    
            }}
        catch {}
            
        try {
            for (var loc of selectedComer){
                const procer=loc.name
                const perc=to_number(loc.percent)*to_number(loc.intensity)/10000
                let toCat=[]
                for (var innerConst of loc.ifraElement.finalConst){
                    toCat.push({
                        ifraElement:innerConst.ifraElement,
                        procedence:procer,
                        fullPercent:innerConst.fullPercent*perc
    
                    })
    
                }
                middleman=middleman.concat(toCat)
    
            }} catch {}

        
        



            
        }




        
        






        // console.log('middleman',middleman)
        let final=[]
        for (var constituyent of middleman){
            const previous=final.findIndex(x => x.ifraElement.id===constituyent.ifraElement.id)
            
            if (previous===-1){
                
                final=final.concat([{
                    ifraElement:constituyent.ifraElement,
                    fullPercent:constituyent.fullPercent,
                    procedence:[constituyent.procedence]
                }])
            }
            else{
                final[previous].fullPercent=final[previous].fullPercent+constituyent.fullPercent
                final[previous].procedence=final[previous].procedence.concat([constituyent.procedence])

            }
            
        
            

        }
        setFinalConst([...final])
        //hola
            

        
        
        
        }
    ,[selectedUses,selectedComps,selectedConsti,fullList,manualComps,selectedMine,selectedComer])



    useEffect(()=>{


       
        let middleTrans=selectedTrans.map(element=>{return {ifraElement:element.ifraElement,procedence:'Element',fullPercent:to_number(element.percent)*to_number(element.intensity)/10000}})
        // console.log(middleTrans)
        // meto trans de manual comp
        for (var compound of manualComps){
            const fullPercento=to_number(compound.percent)/100
            const proc=compound.name
            let toCat=[]
            let nonIfra=[]

        for (var innerConst of compound.composition){

            
            if (innerConst.type=='trans'){
                const match=transparency.find(x=>x.cas==innerConst.cas)
               
                toCat.push({
                    ifraElement:match,
                    procedence:proc,
                    fullPercent:to_number(innerConst.percent)*fullPercento/100

                })
                
                
            }
            
           
        }
        middleTrans=middleTrans.concat(toCat)
    }
    
    // console.log('cviene2',selectedMine)
    try {
    for (var loc of selectedMine){
        const procer=loc.ifraElement.name
        const perc=to_number(loc.percent)*to_number(loc.intensity)/10000
        let toCat=[]
        for (var innerConst of loc.ifraElement.finalTrans){
            toCat.push({
                ifraElement:innerConst.ifraElement,
                procedence:procer,
                fullPercent:innerConst.fullPercent*perc

            })

        }
        middleTrans=middleTrans.concat(toCat)

    }} catch {}
        
        // console.log(middleTrans)
        let final=[]
        for (var constituyent of middleTrans){
            const previous=final.findIndex(x => x.ifraElement.cas==constituyent.ifraElement.cas)
            //console.log('previous',previous)
            
            if (previous===-1){
                
                final=final.concat([{
                    ifraElement:constituyent.ifraElement,
                    fullPercent:constituyent.fullPercent,
                    procedence:[constituyent.procedence]
                }])
            }
            else{
                final[previous].fullPercent=final[previous].fullPercent+constituyent.fullPercent
                final[previous].procedence=final[previous].procedence.concat([constituyent.procedence])

            }
            
        
            

        }
        // console.log('final, master',final)
        setFinalTrans([...final])
        







    }
    ,[selectedTrans,manualComps,selectedMine,selectedComer])



    
    
  return (
    <div ref={ref}>
        <Container>
        
        <LogicTable finalConst={finalConst} search_min_cat={search_min_cat} selectedUses={selectedUses} {...{selectedComps,selectedConsti,restElements,manualComps,finalTrans,selectedTrans,fullListComps,tresh}}/>
        </Container> 

            
    </div>
  )
})

//raf

//falta:
//arreglar los token, parece que solo va access por ahora pero no hace el cambio a refresh
//que si te equivocas de password o wathever error que aparezca en el login
//landing page first
