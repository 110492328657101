import React, { useEffect, useState } from 'react'
import { Container,Form,Button,Table } from 'react-bootstrap'
import { AiFillDelete } from 'react-icons/ai'
import { RestForm } from './restingprods/RestForm'
import { RestTable } from './restingprods/RestTable'
import { useTranslation } from 'react-i18next';


export const RestingProds = ({setRestElements}) => {
  const { t, i18n } = useTranslation("newformulas");
  const [randEl,setRandEl]=useState('')
  const handleChange=(e)=>{
    setRandEl(e.target.value)
  }
  const [randArray,setRandArray]=useState((window.localStorage.getItem('restStorage') ? JSON.parse(window.localStorage.getItem('restStorage')) : []))
  const inputRandEl=(e)=>{
    
    if (e.code == 'Enter' || e.code =='NumpadEnter') {
      e.preventDefault()
      setRandArray(randArray.concat([{name:randEl,cas:'',percent:'',intensity:'100'}]))
      setRandEl('')
    }}
  const removeElement=(index)=>{
    
    let o=randArray
    const removed=o.splice(index,1)
    
    setRandArray([...o])
  }
  useEffect(()=>{setRestElements(randArray)},[randArray])

  useEffect(()=>{
    window.localStorage.setItem('restStorage',JSON.stringify(randArray))
  },[randArray])

  
  return (
    <div><Container style={{width: "70%",textAlign:'center'}}>
        
    <h3>{t("Input unknown elements")}</h3>
    <hr></hr>
    <Container style={{width: "60%"}}>
    <RestForm inputRandEl={inputRandEl} randEl={randEl} handleChange={handleChange}/>
</Container>
{(randArray.length==0)? <></>: <RestTable randArray={randArray} removeElement={removeElement} setRandArray={setRandArray} />}
    </Container></div>
  )
}
