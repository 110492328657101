import React from 'react'

function MenuItem(props) {
    const { children, className } = props;
  return (
    <div className={`menu-item-1 ${className || ""}`}>
        <div className='menu-item roboto-normal-black-20px'>
            {children}
        </div>
    </div>
  )
}

export default MenuItem