import React, { useEffect, useState, useRef } from 'react'
import { FormulaTable } from './formulas/FormulaTable'
import { RestingProds } from './newformula/RestingProds'
import { SelectProd } from './newformula/SelectProd'
import { UseofProd } from './newformula/UseofProd'
import { Form,Button,Container } from 'react-bootstrap'
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { Logic } from './newformula/logic/Logic'
import { useReactToPrint } from 'react-to-print';
import { PdfToPrint } from './newformula/pdf/PdfPrint'
import ReactToPrint from 'react-to-print'
import './pdfprint.css'
import axiosInstance from './protectedRoute/axios'
import axios from 'axios'
import { PdfRead } from './newformula/PdfRead'
import { useTranslation } from 'react-i18next';
import { cifrar,descifrar } from './protectedRoute/encryption';
import useStore from './formulas/stores/main'
import { Tresh } from './newformula/tresh/Tresh'
import ReactGA from "react-ga4";







const NONE = { display: "none" }


export const NewFormula = () => {
  const state = useStore()


  const { t, i18n } = useTranslation("newformulas");
  const navigate = useNavigate();
  

  // const [token] = useCookies(['access_token','refresh_token','username'])
  // useEffect(()=>{
  //   if (!token['access_token']){
  //   navigate('/')}}
  //   ,[token])

  // console.log(localStorage.getItem('access_token'))
  useEffect(()=>{
     if (!descifrar(localStorage.getItem('access_token'))){
     navigate('/')}}
     ,[descifrar(localStorage.getItem('access_token'))])

    



    

     


     
  const [transparency,setTransparency]=useState(require('../f_trans.json'))
  const [synonyms,setSynonyms]=useState(require('../synonyms.json'))
  const [fullList,setFullList]=useState([])
  const [fullListComps,setFullListComps]=useState([])


  const [selectedTrans,setSelectedTrans]=useState((window.localStorage.getItem('transStorage') ? JSON.parse(window.localStorage.getItem('transStorage')) : []))
  const [selectedUses,setSelectedUses] = useState([])
  const [selectedConsti,setSelectedConsti]=useState([])
  const [selectedComps,setSelectedComps]=useState([])
  const [restElements,setRestElements]=useState([])
  const [manualComps,setManualComps]=useState([])
  const [tresh,setTresh] = useState((window.localStorage.getItem('tresh') ? JSON.parse(window.localStorage.getItem('tresh')) : '100'))
  const [selectedMine,setSelectedMine]=useState([])
  const [selectedComer,setSelectedComer]=useState([])


  const [finalTrans,setFinalTrans]=useState([])
  const [finalConst,setFinalConst]=useState([])
  // useEffect(()=>console.log(finalConst),[finalConst])
  //titulo
  const [title,setTitle]=useState((window.localStorage.getItem('titleStorage') ? JSON.parse(window.localStorage.getItem('titleStorage')) : ''))
  const [updateBool,setUpdateBool]=useState((window.localStorage.getItem('updateBoolStorage') ? JSON.parse(window.localStorage.getItem('updateBoolStorage')) : false))
  const [formulaid,setFormulaId] = useState((window.localStorage.getItem('idStorage') ? JSON.parse(window.localStorage.getItem('idStorage')) : ''))
  

  // useEffect(()=>{
  //   setSelectedTrans(transparency.map((l)=>{return({ifraElement:l,percent:'54',intensity:'100'})})) // check if it fetches
    
  // },[])
  // useEffect(()=>{

  //to generate backend data
  // useEffect(() => {
  //   updateFormData({...postData,name:title})
  //   console.log(postData)
  // },[title])

  // useEffect(() => {
  //   console.log('manual comps print',manualComps)
  // },[manualComps])
      
    
 // here will be code to connect to server and send form


//  useEffect(() => {
//   console.log('amnual comps', selectedUses)
// }, [selectedUses])

 const minNumber_mod=(l)=>{
  if (l.ifraElement.ifra_type=='PROHIBITION_RESTRICTION' && l.procedence.includes("Element")==false){
      return minForPR(l.ifraElement.cas_number)

  }

  return (selectedUses.length==0 ? 'Use not selected' : search_min_cat(l.ifraElement).min)


}
function minForPR(cas){
  if (cas.includes('494-40-6')){
      return 0.01

  }
  else if (cas.includes('140-29-4')){
      return 0.01

  }
  else if (cas.includes('78-59-1')){
      return 0.0013
  }
  else if (cas.includes('531-59-9')){
      return  0.01
  }
  else if (cas.includes('94-59-7')){
      return 0.01
  }
}
const search_min_cat = (element) => {
  const posibleMins=selectedUses.map(use=>{return Number(element[use.category])})
  const min = Math.min(...posibleMins);
  const index = posibleMins.indexOf(min);
  const category = (index === -1 ? "See Notebox" : selectedUses[index].category)  
  // return Math.min(...posibleMins)
  return {min:(index === -1 ? 'See Notebox' : min),category:category}
}

const catBox_mod=(l)=>{
  if (search_min_cat(l.ifraElement).category=='See Notebox'){
      return 'See Notebox'
  }
  else {
      return `${(selectedUses.find(x => x.category === search_min_cat(l.ifraElement).category).name)} (${search_min_cat(l.ifraElement).category})`
  }
  

}

const noteBox_mod=(l)=>{
  if (l.ifraElement.prohibit_notes.length!=0) {
    return l.ifraElement.prohibit_notes
  }
  else if (l.ifraElement.restricted_notes.length!=0) {
    return l.ifraElement.restricted_notes
  }
  else if (l.ifraElement.specified_notes.length!=0){
    return l.ifraElement.specified_notes
  }
  
}
const innerDil=(l)=>{
  let fullIfra=0
  for (var i of l.ifraElement.composition){
      fullIfra=fullIfra+Number(i.concen_constituent)

  }
  const otherDil=100-fullIfra
  return (otherDil/100)*((Number(l.percent)/100)*(Number(l.intensity)/100)*100)
}

const [fullDiluent,setFullDiluent]=useState(0)
const [seeDiluents,setSeeDiluents]=useState(false)
useEffect(()=>{
    let dil=0
    for (var i of selectedConsti){
        dil=dil+(Number(i.percent)/100)*(1-(Number(i.intensity)/100))*100

    }
    for (var i of selectedComps){
        dil=dil+((Number(i.percent)/100)*(1-(Number(i.intensity)/100))*100+innerDil(i))

    }
    for (var i of restElements){
        dil=dil+(Number(i.percent)/100)*(1-(Number(i.intensity)/100))*100

    }
    setFullDiluent(dil)
}
,[selectedComps,selectedConsti,restElements])



  const initialFormData = Object.freeze({
      name: ''
    });

  const [postData, updateFormData] = useState(initialFormData);
  const initialFormDataFormula = Object.freeze({
      cas: '',
      name: '',
      formula_over: '', 
      type_of_restriction: '',
      max_Restr: '',
      cat_restr: '',
      notes: '',
      procedence: '',
      total_in_finished: ''
    });
  
  const cas_array = []
  const name_array = []
  const formula_over = []
  const type_of_restriction = []
  const max_Restr = []
  const cat_restr = []
  const notes = []
  const procedence = []
  const total_in_finished = []
  
  const [postDataFormula, updateFormDataFormula] = useState(initialFormDataFormula);

  // },[localStorage])
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => document.title = title,
    onAfterPrint: () => document.title = title
  });

  useEffect(()=> {
    updateFormData({...postData,name:title});

    finalConst.map((l) => {
      cas_array.push(l.ifraElement.cas_number)
      name_array.push(l.ifraElement.name_ifra)
      formula_over.push(postData.name)
      type_of_restriction.push(l.ifraElement.ifra_type)
      max_Restr.push(minNumber_mod(l))
      cat_restr.push(selectedUses.length==0 ? 'Use not selected' : catBox_mod(l))
      notes.push(noteBox_mod(l))
      procedence.push(l.procedence[0])
      total_in_finished.push(l.fullPercent)
    })

    
    

    updateFormDataFormula({cas:cas_array,name:name_array,formula_over:formula_over,type_of_restriction:type_of_restriction,max_Restr:max_Restr,cat_restr:cat_restr,notes:notes,procedence:procedence,total_in_finished:total_in_finished})

    

    
  },[title,finalConst,selectedComps,selectedConsti,restElements])



  //working with backend frontend connection of form
  const handleSubmit = (e) => {
    ReactGA.event({
        category:'submit_formula',
        action:'submited_formula',
        label:'Formula_Submited',
    })
    if (title==''){
      window.alert('the formula needs a title')
    }
    else{
      e.preventDefault();
		


    axiosInstance.get('/user/auth/user/').then(function (response) {
      // handle success
      //console.log(response.data)
      axiosInstance.post(`formulas-overview/`, {"name":postData.name,"author":response.data.id}).then(function (response){
        //console.log(response.data.id)
      
      for (let i = 0; i < postDataFormula.cas.length; i++){
        //console.log(postDataFormula.total_in_finished[i])
          axiosInstance.post(`formulas-body/`,{"cas":postDataFormula.cas[i],"name":postDataFormula.name[i],"formula_over":response.data.id,
        "type_of_restriction":postDataFormula.type_of_restriction[i],"max_Restr":postDataFormula.max_Restr[i],"cat_restr":postDataFormula.cat_restr[i],
        "notes":postDataFormula.notes[i] ? postDataFormula.notes[i] : "empty","procedence":postDataFormula.procedence[i],"total_in_finished":postDataFormula.total_in_finished[i]})
        }

      restElements.map((el) => {
        axiosInstance.post(`formulas-body/`,{"cas":"non-ifra","name":el.name,"formula_over":response.data.id,
        "type_of_restriction":"none","max_Restr":"none","cat_restr":"none",
        "notes":"none","procedence":"rest_elements","total_in_finished":(Number(el.intensity)*Number(el.percent))/100})
        })

      finalTrans.map((e) => {
        axiosInstance.post(`formulas-body/`,{"cas":e.ifraElement.cas,"name":e.ifraElement.name,"formula_over":response.data.id,
      "type_of_restriction":"none","max_Restr":"none","cat_restr":"none", "notes":"none","procedence":"Transparency_List",
      "total_in_finished":e.fullPercent})
      })
      
      selectedTrans.map((e) => {
        axiosInstance.post(`formulas-body/`,{"cas":e.ifraElement.cas,"name":`Diluents of ${e.ifraElement.name}`,"formula_over":response.data.id,
        "type_of_restriction":"none","max_Restr":"none","cat_restr":"none",
        "notes":"none","procedence":"Transparency_List","total_in_finished":(Number(e.percent)/100)*(1-(Number(e.intensity)/100))*100})
      })

      if (Object.keys(selectedConsti).length !== 0){
        selectedConsti.map((l)=>{
          axiosInstance.post(`formulas-body/`,{"cas":l.ifraElement.cas_number,"name":`Diluents of ${l.ifraElement.name_ifra}`,"formula_over":response.data.id,
        "type_of_restriction":"none","max_Restr":"none","cat_restr":"none",
        "notes":"none","procedence":"constituyents","total_in_finished":((Number(l.percent)/100)*(1-(Number(l.intensity)/100))*100)})
      })}
      if (Object.keys(selectedComps).length !== 0){
        selectedComps.map((l)=>{
          axiosInstance.post(`formulas-body/`,{"cas":l.ifraElement.CAS,"name":`Diluents of ${l.ifraElement.ncs_name}`,"formula_over":response.data.id,
        "type_of_restriction":"none","max_Restr":"none","cat_restr":"none",
        "notes":"none","procedence":"constituyents","total_in_finished":((((Number(l.percent)/100)*(1-(Number(l.intensity)/100))*100)+innerDil(l)))})
      })}
      if (Object.keys(restElements).length !== 0){
        restElements.map((l)=>{
          axiosInstance.post(`formulas-body/`,{"cas":"non-ifra","name":`Diluents of ${l.name}`,"formula_over":response.data.id,
        "type_of_restriction":"none","max_Restr":"none","cat_restr":"none",
        "notes":"none","procedence":"constituyents","total_in_finished":(((Number(l.percent)/100)*(1-(Number(l.intensity)/100))*100))})
      })}

      manualComps.map((l) => {
        for (let i = 0; i < l.composition.length; i++){
          if (l.composition[i].selectedIfra == 'no'){
            //console.log('name componenet ', l.composition[i])
            axiosInstance.post(`formulas-body/`,{"cas":"non-ifra","name":l.composition[i].component,"formula_over":response.data.id,
        "type_of_restriction":"none","max_Restr":"none","cat_restr":"none",
        "notes":"none","procedence":l.name ,"total_in_finished":(((Number(l.percent)/100)*(Number(l.composition[i].percent)/100))*100)})
          }
        }
      })


      //Metadatos
      axiosInstance.post('metadatos/',{"name":title,"selectedTrans":selectedTrans,"selectedUses":selectedUses,"selectedConsti":selectedConsti,"selectedComps":selectedComps,"restElements":restElements,"manualComps":manualComps,"finalTrans":finalTrans,"finalConst":finalConst,"formula_over":response.data.id,"tresh":tresh,"selectedMine":selectedMine,"selectedComer":selectedComer})
      
      ///METADATA COMMIT:
      // selectedUses.map((l) => {
      //   console.log(l.name)
      //   console.log(l.place)
      //   console.log(l.category)
      //   console.log(response.data.id)
      //   axiosInstance.post('meta/',{"object_name":'selectedUses',"name":l.name,"place_us":l.place,"category_su":l.category,
      //   'cas_number':'none','intensity':'none','percent':'none','formula_over':response.data.id})
      // }
      // )
      // selectedConsti.map((l) => {
      //   console.log('intensity value consti',l.intensity)
      //   console.log('l',l,typeof l.intensity)
      //   axiosInstance.post('meta/',{"object_name":'selectedConsti',"name":l.ifraElement.name_ifra,"place_us":'none',"category_su":'none',
      //   'cas_number':l.ifraElement.cas_number,'intensity':l.intensity,'percent':l.percent,'formula_over':response.data.id})
      // }
      // )
      // selectedComps.map((l) => {
      //   axiosInstance.post('meta/',{"object_name":'selectedComps',"name":l.ifraElement.ncs_name,"place_us":'none',"category_su":'none',
      //   'cas_number':l.ifraElement.CAS,'intensity':l.intensity,'percent':l.percent,'formula_over':response.data.id})
      // }
      // )
      // restElements.map((l) => {
      //   axiosInstance.post('meta/',{"object_name":'restElements',"name":l.name,"place_us":'none',"category_su":'none',
      //   'cas_number':'none','intensity':l.intensity,'percent':l.percent,'formula_over':response.data.id})
      // }
      // )
      // manualComps.map((l) => {
      //   axiosInstance.post('meta/',{"object_name":'manualComps',"name":l.name,"place_us":'none',"category_su":'none',
      //   'cas_number':'none','intensity':'none','percent':l.percent,'formula_over':response.data.id})
      // }
      // )
      // selectedTrans.map((l) => {
      //   axiosInstance.post('meta/',{"object_name":'selectedTrans',"name":l.ifraElement.name,"place_us":'none',"category_su":'none',
      //   'cas_number':l.ifraElement.cas,'intensity':l.intensity,'percent':l.percent,'formula_over':response.data.id})
      // }
      // )
      

      
      }
        
      )
      

      
    }).then(navigate('/formulas/'))

    }
		

	};

  const handleUpdate = (e) => {
    //console.log('update')
    const data = state.meta.slice(-1)
    //console.log(descifrar(formulaid))
    axiosInstance.put('metadatos/',{"name":title,"selectedTrans":selectedTrans,"selectedUses":selectedUses,"selectedConsti":selectedConsti,"selectedComps":selectedComps,"restElements":restElements,"manualComps":manualComps,"finalTrans":finalTrans,"finalConst":finalConst,"formula_over":descifrar(formulaid),"tresh":tresh,"selectedMine":selectedMine,"selectedComer":selectedComer})
    
    navigate('/formulas')
    
    // axiosInstance.update('metadatos/',{"name":title,"selectedTrans":selectedTrans,"selectedUses":selectedUses,"selectedConsti":selectedConsti,"selectedComps":selectedComps,"restElements":restElements,"manualComps":manualComps,"finalTrans":finalTrans,"finalConst":finalConst,"formula_over":response.data.id})

  }

  useEffect(()=>{
    
    window.localStorage.setItem('transStorage',JSON.stringify(selectedTrans))
  },[selectedTrans])
  useEffect(()=>{
    
    window.localStorage.setItem('titleStorage',JSON.stringify(title))
  },[title])
  useEffect(()=>{
    
    window.localStorage.setItem('updateBoolStorage',JSON.stringify(updateBool))
  },[updateBool])
  useEffect(()=>{
    
    window.localStorage.setItem('tresh',JSON.stringify(tresh))
  },[tresh])

  return (
    <div><br/><br/><br></br><br></br>
    
    
    <UseofProd setSelectedUses={setSelectedUses}/>
    <br></br><br></br>
    <SelectProd setSelectedConsti={setSelectedConsti} setSelectedComps={setSelectedComps} setFullList={setFullList} transparency={transparency} synonyms={synonyms} setSelectedTrans={setSelectedTrans} selectedTrans={selectedTrans} setFullListComps={setFullListComps} setSelectedMine={setSelectedMine} setSelectedComer={setSelectedComer}/>
    <br></br><br></br>
    <RestingProds setRestElements={setRestElements}/>
    
    
    <br></br><br></br>

    
    <PdfRead fullList={fullList} {...{setManualComps,transparency,synonyms,fullListComps}}/>
    <br></br><br></br>
    <Tresh  {...{tresh,setTresh}} />
    <br></br><br></br><hr></hr><hr></hr>
      <Container>
      {(updateBool?<h1 style={{textAlign:'left'}}>{title}</h1>:<Form.Control placeholder={t('Title')} width={'10px'} value={title} onChange={(e)=>{setTitle(e.target.value)}}  style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'left',display:'flex',fontSize:'50px'}}/>)}
      </Container>
      
      <Logic {...{selectedUses,selectedComps,selectedConsti,restElements,manualComps,fullList,finalConst,setFinalConst,updateFormData,selectedTrans,finalTrans,setFinalTrans,transparency,fullListComps,tresh,selectedMine,selectedComer}} ></Logic> 
      
      {/* ref={componentRef} */}
      
      <Container>
      {(updateBool?<><Button onClick={handleUpdate} variant="outline-success">{t("Update")}</Button>{' '}</>:<><Button onClick={(e)=>{handleSubmit(e)}} variant="outline-success">{t("Save")}</Button>{' '}</>)}
      {/* <ReactToPrint
          trigger={() => <a href="#">Print this out!</a>}
          content={() => componentRef.current}
        /> */}
      <PdfToPrint {...{selectedUses,selectedComps,selectedConsti,restElements,fullList,title,setTitle,finalConst,setFinalConst,finalTrans}} ref={componentRef} documentTitle={title} onBeforePrint={() => document.title = "Print page title"} onAfterPrint={() => document.title = 'print'}></PdfToPrint>
      <Button variant="outline-primary" onClick={(e)=>{handlePrint(e)}}>{t("Print Certificate")}</Button>{' '}
      {/* <Button variant="outline-danger">{t("Delete")}</Button>{' '} */}
    </Container>
    <br></br><br></br>
    
    
    </div>
  )
}
//"To print a functional component ensure it is wrapped with `React.forwardRef`, and ensure the forwarded ref is used. See the README for an example: https://github.com/gregnb/react-to-print#examples"