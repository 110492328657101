import React, { useEffect, useRef } from 'react'
import Head from './landing/head'
import './landing/head.css'
import './landing/landing.css'
import img1 from './landing/media/----------2-1@1x.png'
import img2 from './landing/media/line-1@1x.svg'
import img3 from './landing/media/Untitled design (5)7.png'
import img4 from './landing/media/------@1x.svg'
import img5 from './landing/media/screenshot--130--1@2x.png'
import Why from './landing/Why'
import How from './landing/how'
import Contact from './landing/contact'
import Footer from './landing/footer'
import TextAnimation from './animations/TextAnimation'
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga";
// import ScrollAnimation from 'react-animate-on-scroll'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, FadeOut, Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";



export const Landing = () => {
    const { t, i18n } = useTranslation("landing");
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    },[])
    return(
        <div className='container-center-horizontal'>
            <div className='destkop-4 screen'>
                <div className='overlap-group10'>
                    <div className='ready'>
                        <Head></Head>
                            <div className='overlap-group'>
                                <img className='x2-1' src={img1} />
                                <img className='line-1' src={img2} />
                                <img className='x4-1' src={img3}/>
                                {/* <img className='image' src={img4}/> */}
                                <h1 className='create-your-product outfit-bold-astronaut-blue-60px'>   <TextAnimation></TextAnimation></h1>
                                <img className='screenshot-130-1' src={img5} />
                            </div>
                            <div id='fader0'></div>
                            <div id='fader01'></div>
                    </div>
                    <Why></Why>
                </div>
                <How></How>
                <Contact></Contact>
                <Footer></Footer>
                
                
            </div>
            
        </div>
        
        
    )

}