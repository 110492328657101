import React from 'react'
import { Form, FormLabel, ListGroup } from 'react-bootstrap'
import { AiFillDelete } from 'react-icons/ai'
import { SpecialTH } from './SpecialTH'
import { SuperSearcher } from './SuperSearcher'

export const PdfSubTable = ({composition,index,pdfComps,setPdfComps,fullList,transparency,synonyms,fullListComps}) => {
  const removeElemento=(index,indice)=>{
    
    let o=pdfComps
    const removed=o[index].composition.splice(indice,1)
    
    setPdfComps([...o])
  }
  const handlePercento=(e,index,indice)=>{
    let obj=pdfComps
    obj[index].composition[indice].percent=e.target.value
    setPdfComps([...obj])
}
const handleCAS=(e,index,indice)=>{
  let obj=pdfComps
  obj[index].composition[indice].cas=e.target.value
  setPdfComps([...obj])
}
const selectIfra=(index,indice)=>{
  let obj=pdfComps
  obj[index].composition[indice].selectedIfra=(obj[index].composition[indice].selectedIfra=='no'?'yes':'no')
  setPdfComps([...obj])

}

  // console.log('composicion pdf here',composition)

  const setColor=(type)=>{
    if (type=='consti'){return 'primary'}
    else if (type=='complex'){return 'secondary'}
    else if (type=='trans'){return 'light'}
    else {return 'dark'}

  }

  
  return (
    <><tr className='table-black' style={{textAlign:'center'}}>

    <th colSpan={1}>CAS</th>
    <th colSpan={1}>CONSTITUENT</th>
    <th colSpan={1}>%</th>
    <th colSpan={1}>POSSIBLE ELEMENT</th>
    <th colSpan={1}>POSSIBLE RESTRICTED ELEMENT</th>
    <th colSpan={1} className='table-danger border border-danger'>DELETE</th>
  </tr>
  {composition.map((elemento,indice)=>{
    let lol=[]
    if (elemento.wordMatch){
      lol=elemento.wordMatch.filter(function(e) { return e !== 'none' })

    }
    
    
    return(
      <tr className={`table-${setColor(elemento.type)}`} style={{textAlign:'center'}}>
        <th>{(elemento.type == 'unknown' ?<Form.Control size='sg' className={`text-${setColor(elemento.type)=='primary' || setColor(elemento.type)=='dark'?'white':'black'}`} value={elemento.cas} onChange={(e)=>{handleCAS(e,index,indice)}}  style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>:(elemento.cas=='none'?'':elemento.cas))}</th>
        <th>{elemento.component}</th>
        <th>
        <Form.Control size='sg' className={`text-${setColor(elemento.type)=='primary' || setColor(elemento.type)=='dark'?'white':'black'}`} value={elemento.percent} onChange={(e)=>{handlePercento(e,index,indice)}}  style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>
        </th>

        <SpecialTH {...{elemento,pdfComps,setPdfComps,index,indice,transparency,fullList}}/>
        {/* {(elemento.ifra=='none'?<th>{''}</th>:<th style={{cursor:'pointer'}} onClick={()=>{selectIfra(index,indice)}} >{elemento.ifra}</th>)} */}
        <th>{lol.length==0 ? '':lol.map((l,index)=>{return(<><p>{l}</p>{lol[index+1]==null?<></>:<hr></hr>}</>)})}</th>
        <th colSpan={1} className='table-danger border border-danger' onClick={()=>{removeElemento(index,indice)}} style={{cursor:'pointer'}}><AiFillDelete size='1.3em'/></th> 
              
      </tr>
    )
  })}
  <SuperSearcher elements={fullList} compounds={fullListComps}  {...{index,pdfComps,setPdfComps,transparency,synonyms}}/>
  
  
  </>
  )
}
