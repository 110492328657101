import React, { useEffect, useState } from 'react'
import { Form, ListGroup } from 'react-bootstrap'

export const SuperSearcher = ({elements,index,pdfComps,setPdfComps,transparency,synonyms,compounds}) => {
    const [search,setSearch]=useState('')
    const handleChange=(e)=>{
        setSearch(e.target.value)
      }
    const [selectedelements,setSelectedelements]=useState([])
    

    useEffect(()=> {
        const val=search
        if (val==''){setSelectedelements([])} 
        else {
        
       
        const matches0 = elements.filter(s => (s.name_ifra.toLowerCase().includes(val.toLowerCase()) || s.cas_number.includes(val)));
        // console.log('ascu',synonyms)
        const preMatches1 = synonyms.filter(s => (s.synonym.toLowerCase().includes(val.toLowerCase())))
        const matches1=preMatches1.map(l=>{return({...elements.find(s => (s.cas_number.includes(l.cas))),synonym:l.synonym})})
        const matches2 = compounds.filter(s => (s.ncs_name.toLowerCase().includes(val.toLowerCase()) || s.CAS.includes(val) || s.other_CAS.includes(val) || s.ncs_botanic_name.toLowerCase().includes(val.toLowerCase())));
        const matches3 = transparency.filter(s => (s.name.toLowerCase().includes(val.toLowerCase()) || s.cas.includes(val)));
        // console.log('matches0',matches0)
        // console.log('matches1',matches1)
        // console.log('matches2',matches2)
        // console.log('matches3',matches3)
        const matches = matches0.concat(matches1,matches2,matches3)
       
        
        setSelectedelements([...matches.slice(0,14)])}},[search])
        
      const ultrasetter=(e,id,index)=>{
        e.preventDefault();
        setSearch('')
        const val=id
        const matches = elements.find(x => x.id === val)

        let obj=pdfComps
        
        obj[index].composition.push({cas: matches.cas_number, component: matches.name_ifra, ifra: 'none', percent: '',type:'consti'})
        
        setPdfComps([...obj])
        
      }
      // useEffect(()=>{console.log('p',pdfComps)},[pdfComps])
      const ultrasetter2=(e,id,index)=>{
        e.preventDefault();
        setSearch('')
        const val=id
        const matches = compounds.find(x => x.id === val)

        let obj=pdfComps
        
        obj[index].composition.push({cas: matches.CAS, component: matches.ncs_name, ifra: 'none', percent: '',type:'complex'})
        
        setPdfComps([...obj])

        // setUltraselectcompounds(ultraselectcompounds.concat({ifraElement:matches,percent:'',intensity:'100'}))
        
      }
      const transparencySetter=(e,cas,index)=>{
        e.preventDefault();
        setSearch('')
        const val=cas
        const matches = transparency.find(x => x.cas === val)
        let obj=pdfComps
        
        obj[index].composition.push({cas: matches.cas, component: matches.name, type: 'trans', percent: '',ifra:'none',wordMatch:matches.wordMatch})
        
        setPdfComps([...obj])

        // setSelectedTrans(selectedTrans.concat({ifraElement:matches,percent:'',intensity:'100'}))
        
      }
      const inputRandEl=(e)=>{
    
        if (e.code == 'Enter' || e.code =='NumpadEnter') {
          e.preventDefault()
          let obj=pdfComps
        
        obj[index].composition.push({cas: 'none', component: search, ifra: 'none', percent: '',selectedIfra:'no',type:'unknown'})
        
        setPdfComps([...obj])
        setSearch('')
        }}
  return (
    <>
    <tr className='table-dark'>
    <th colSpan={6}>
    <Form.Control size='sg' className='text-white' placeholder='Input Element' onKeyDown={inputRandEl} value={search} onChange={(e)=>{handleChange(e)}}  style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px'}}/>


    
    
    
  <div style={{justifyContent: 'center',position:'absolute'}}>
  <ListGroup style={{alignText:'left',width:'80%',border:'2px solid rgba(0, 0, 0, 0.8)'}}>
            {selectedelements.map((element,index1)=>{
              // return(<ListGroup.Item key={element.id} action variant='primary' onClick={(e)=>{ultrasetter2(e,element.id)}} >{element.ncs_name} {element.CAS}</ListGroup.Item>)
              return(Object.keys(element).length==6 ? <ListGroup.Item key={index1} action variant='primary' onClick={(e)=>{ultrasetter2(e,element.id,index)}} >{element.ncs_name} {element.CAS} {element.other_CAS}</ListGroup.Item> :
              (Object.keys(element).length==3 ? <ListGroup.Item key={index1} action variant='black' onClick={(e)=>{transparencySetter(e,element.cas,index)}} >{element.name} {element.cas}</ListGroup.Item>: <ListGroup.Item key={index1} action variant='primary' onClick={(e)=>{ultrasetter(e,element.id,index)}} >{element.name_ifra} {(element.synonym!=null ? `[synonym: ${element.synonym}]`:'')} {element.cas_number} </ListGroup.Item>))
            
            })}
      
        </ListGroup>
  {/* <ListGroup style={{alignText:'left',width:'80%'}}>
    {selectedelements.map(element=>{
      return(<ListGroup.Item key={element.id} action variant='primary' onClick={(e)=>{ultrasetter(e,element.id,index)}} >{element.name_ifra} {element.cas_number}</ListGroup.Item>)
    })}

</ListGroup> */}
</div></th></tr></>
  )
}
