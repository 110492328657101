import React, { useState } from 'react'
import { ListGroup } from 'react-bootstrap'

export const SpecialTH = ({elemento,pdfComps,setPdfComps,index,indice,transparency,fullList}) => {
    const [nestle,setNestle]=useState(false)
    const setElement=(ll)=>{
        
        let a=pdfComps
        
        
        a[index].composition[indice]={
            cas:ll.cas.replaceAll('\r\n', '\n'),
            component:(ll.type=='syn'? `${fullList.find(x=>x.cas_number==ll.cas.replaceAll('\r\n', '\n')).name_ifra} [synonym: ${ll.name}]`:ll.name),
            type:(ll.type=='syn'?'consti':ll.type),
            percent:a[index].composition[indice].percent,
            wordMatch:(ll.type=='trans'? transparency.find(x=>x.cas==ll.cas).wordMatch :'')
    }
    setPdfComps([...a])


    }
  return (<>
    {(elemento.possibleMatch && elemento.type=='unknown')?
        <th style={{cursor:'pointer'}} onClick={()=>{setNestle(!nestle)}}>View Suggestions
        {nestle ?
        <div style={{justifyContent: 'center',position:'absolute'}}>
  <ListGroup style={{alignText:'left',width:'80%',border:'2px solid rgba(0, 0, 0, 0.8)'}}> 
            {elemento.possibleMatch.map((ll,index1)=>{
             return(<ListGroup.Item key={index1} action variant='primary' onClick={(e)=>{setElement(ll)}} >{ll.name} {ll.cas}</ListGroup.Item> 
  )})}
      
        </ListGroup>
</div>:<></>}</th>:<th>{''}</th>}</>
  )
}
