import React from 'react'
import './head.css'
import MenuItem from './headComp/MenuItem'
import SignIn from './headComp/SignIn'
import logo from './media/innorma--6--1@2x.png'
import img1 from './media/----------2-1@1x.png'
import img2 from './media/line-1@1x.svg'
import img3 from './media/----------4-1@1x.png'
import img4 from './media/------@1x.svg'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import { useTranslation } from 'react-i18next';




export default function Head() {
  const { t, i18n } = useTranslation("landing");
  const navigate = useNavigate(); 
  const linked=() => {
    navigate('/login')

  }
  return (
    <div className='up'>
        <img className='innorma-6-1' src={logo}/>
        <div className='animation'>
            <MenuItem><a href='#about' style={{color: "#0a3a62"}}>{t("About Us")}</a></MenuItem>
            <MenuItem className='menu-item-2'><a href='#how' style={{color: "#0a3a62"}}>{t("Program")}</a></MenuItem>
            <MenuItem className='menu-item-3'><a href='#contact' style={{color: "#0a3a62"}}>{t("Contact")}</a></MenuItem>
            <SignIn ><a as ={Link} onClick={()=>{linked()}} to='/login' style={{cursor:'pointer'}}>{t("Sign In")}</a></SignIn>
        </div>
    </div>
  )
}
