import React from 'react'
import './footer.css'
import PhoneEnabledSharpIcon from '@mui/icons-material/PhoneEnabledSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import CookieConsent from "react-cookie-consent";
import i18n from '../../i18n'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';



function Footer() {
    const { t, i18n } = useTranslation("landing");

    var d = new Date(); 
    const currentYear = d.getFullYear()
  return (
    <div className='foot'>
        <CookieConsent>{t("We store registered data for user experience, we don't share your personal information with anyone.") + t(" You can read our ")}  <a href='./terms/#CookiesPolicy'>Cookies</a>  {t(" policy here ")}</CookieConsent>
        {/* #This website uses cookies to enhance the user experience. */}
        <div className='innorma'><EmailSharpIcon/><a href='mailto:innorma.reg@gmail.com'>innorma.reg@gmail.com</a></div>
        <div className='phone'><PhoneEnabledSharpIcon/><a href='https://wa.me/34675139809'>+34 675 139 809</a></div>
        <div className='language'><Link  to='/terms' className='language'>Terms</Link></div>
        <div className='language'><button  className='language' onClick={() => i18n.changeLanguage('es')} >{t("Spanish")}</button><button className='language' onClick={() => i18n.changeLanguage('en')} >{t("English")}</button></div>
        <div className='copy'><small> Copyright &copy; 2022, Jonatan Bella & Agustin Bustos Barton. {t("All Rights Reserved")}</small></div>
    </div>
  )
}

export default Footer