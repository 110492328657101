import React, { useEffect, useState }  from 'react'
import {Form,Button, Card } from 'react-bootstrap';
import { Link} from 'react-router-dom'
//import '../assets/css/mdb.dark.min.css'
// import { useCookies } from 'react-cookie';

import axiosInstance from './protectedRoute/axios';
import { useNavigate } from 'react-router-dom';
import { cifrar,descifrar } from './protectedRoute/encryption';



export const Login = () => {
  const navigate = useNavigate();
  // const [token,setToken]=useCookies(['access_token','refresh_token','username'])
  // useEffect(()=>{
  //   if (token['access_token']){
  //   navigate('/formulas')}}
  //   ,[token])

	const initialFormData = Object.freeze({
		email: '',
		password: '',
	});

	const [formData, updateFormData] = useState(initialFormData);
  const [passCard,setPassCard]=useState(false)

	const handleChange = (e) => {
    // if (e.code=='Enter'){console.log(e)}
    
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		{/*console.log(formData);*/}

		axiosInstance
    .post(`users/login`, {
				email: formData.email,
				password: formData.password,
			})
      .then((res) => {
        // console.log('si')
        // setToken('access_token', res.data.access)
        // setToken('refresh_token',  res.data.refresh)
        // setToken('username', res.data.username)
        // axiosInstance.defaults.headers['Authorization'] =
				// 	'Bearer ' + token['refresh_token'];
        // console.log('res.data.access',res.data.access)
        // console.log(typeof res.data.access)
				localStorage.setItem('access_token', cifrar(res.data.access));
				localStorage.setItem('refresh_token', cifrar(res.data.refresh));
        localStorage.setItem('username', res.data.username);
        localStorage.setItem('company_name', res.data.company_name);
        localStorage.setItem('email',res.data.email)

        // localStorage.setItem('ref', res.data.id);
        // console.log(descifrar(localStorage.getItem('access_token')))
        
				axiosInstance.defaults.headers['Authorization'] =
					'Bearer ' + descifrar(localStorage.getItem('access_token'));
        // useEffect(()=>{if (localStorage['access_token']){navigate('/formulas')}},[localStorage])
        navigate('/formulas/');
        
				//DEJAR PARA ADMIN ISSUESS
        if (res.data.email == 'jonatanbella@gmail.com'){
          console.log(res.data);
        }
        
				
			}).catch(()=>{setPassCard(true)}) 
        
        // setTimeout(setPassCard(true), 5000)
        
        
        
	};

  return (
    <div><br/><br/><br/><h1 style={{textAlign:'center'}}>Login</h1><br/><br/>
    <div style={{display:"flex",alignItem:'center',justifyContent:'center',height:'100vh',textAlign:'center'}}>
    
   
    <Form>
  <Form.Group className="mb-3">
    <Form.Label>Email address</Form.Label>
    <Form.Control type="email" placeholder="Enter email" id="email" style={{width:'400px'}} name="email" onChange={handleChange}/>
    <Form.Text className="text-muted">
      {/* We'll never share your email with anyone else. */}
    </Form.Text>
  </Form.Group>

  <Form.Group className="mb-3">
    <Form.Label>Password</Form.Label>
    <Form.Control type="password" placeholder="Password" id="password" name="password" onChange={handleChange} onKeyDown={(e)=>{return((e.code == 'Enter' || e.code =='NumpadEnter')?handleSubmit(e):null)}}/>
  </Form.Group>
  
  <Button variant="primary" type="submit" onClick={handleSubmit}>
    Submit
  </Button>
  <br></br><br></br>
  {passCard?<>
  <Card text='danger' border='danger'>
      <Card.Body >Incorrect password or username</Card.Body>
    </Card></>:<></>}
</Form>

    
</div>
    </div>
    
    
    
  )
}

/*<div style={{display:"flex",alignItem:'center',justifyContent:'center',height:'100vh'}}>
   </div>
 */

/* <div class="row">
          <!--Grid column-->
          <div class="col-lg-4 col-md-12 mb-4">
            <div class="form-outline mb-3">
              <input type="text" id="form1" class="form-control" />
              <label class="form-label" for="form1" style="margin-left: 0px"
                     >Example label</label
                >
              <div class="form-notch">
                <div class="form-notch-leading" style="width: 9px"></div>
                <div class="form-notch-middle" style="width: 88.8px"></div>
                <div class="form-notch-trailing"></div>
              </div>
            </div>
*/



/*<form>
              <div className="form-outline">
                <input
                       type="email"
                       className="form-control"
                       id="exampleInputEmail1"
                       aria-describedby="emailHelp"
                       autocomplete="off"
                       style={{"backgroundRepeat":"no-repeat","backgroundAttachment":"scroll","backgroundSize":"16px 18px","backgroundPosition":"98% 50%"}}
                       />
                <label
                       for="exampleInputEmail1"
                       className="form-label"
                       style={{"marginLeft":"0px"}}
                       >Email address</label>
                <div className="form-notch">
                  <div className="form-notch-leading" style={{"width":"9px"}}></div>
                  <div
                       className="form-notch-middle"
                       style={{"width":"88.8px"}}
                       ></div>
                  <div className="form-notch-trailing"></div>
                </div>
              </div>
           <br/>
              <div className="form-outline mb-3">
                <input
                       type="password"
                       className="form-control"
                       id="exampleInputPassword1"
                       autocomplete="off"
                       style={{"backgroundRepeat":"no-repeat","backgroundAttachment":"scroll","backgroundSize":"16px 18px","backgroundPosition":"98% 50%"}}
                       />
                <label
                       for="exampleInputPassword1"
                       className="form-label"
                       style={{"marginLeft":"0px"}}
                       >Password</label>
                <div className="form-notch">
                  <div className="form-notch-leading" style={{"width":"9px"}}></div>
                  <div className="form-notch-middle" style={{"width":"64px"}}></div>
                  <div className="form-notch-trailing"></div>
                </div>
              </div>
              
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>*/

            /*
   <form>
              <div className="form-outline">
                <input
                       type="email"
                       className="form-control"
                       id="exampleInputEmail1"
                       aria-describedby="emailHelp"
                       autocomplete="off"
                       style={{"backgroundRepeat":"no-repeat","backgroundAttachment":"scroll","backgroundSize":"16px 18px","backgroundPosition":"98% 50%"}}
                       />
                <label
                       for="exampleInputEmail1"
                       className="form-label"
                       style={{"marginLeft":"0px"}}
                       >Email address</label>
                <div className="form-notch">
                  <div className="form-notch-leading" style={{"width":"9px"}}></div>
                  <div
                       className="form-notch-middle"
                       style={{"width":"88.8px"}}
                       ></div>
                  <div className="form-notch-trailing"></div>
                </div>
              </div>
           <br/>
              <div className="form-outline mb-3">
                <input
                       type="password"
                       className="form-control"
                       id="exampleInputPassword1"
                       autocomplete="off"
                       style={{"backgroundRepeat":"no-repeat","backgroundAttachment":"scroll","backgroundSize":"16px 18px","backgroundPosition":"98% 50%"}}
                       />
                <label
                       for="exampleInputPassword1"
                       className="form-label"
                       style={{"marginLeft":"0px"}}
                       >Password</label>
                <div className="form-notch">
                  <div className="form-notch-leading" style={{"width":"9px"}}></div>
                  <div className="form-notch-middle" style={{"width":"64px"}}></div>
                  <div className="form-notch-trailing"></div>
                </div>
              </div>
              
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>*/