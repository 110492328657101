import React, { useState } from 'react'

import { Container, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const Tresh = ({tresh,setTresh}) => {
    const { t, i18n } = useTranslation("newformulas")
    const [randEl,setRandEl]=useState('')
    const inputRandEl=(e)=>{
    
        if (e.code == 'Enter' || e.code =='NumpadEnter') {
          e.preventDefault()
          setTresh(e.target.value)
          setRandEl('')
          
        }}
  return (
    <div><Container style={{width: "70%"}}> 
        
    <h3 style={{textAlign:'center'}}>{t('Expected percentage in the final solution: ')+tresh+'%'}</h3>
    <hr></hr>
    <Container style={{width: "60%"}}>
    <Form.Group className="mb-3" controlId="formBasicSearch">
      <Form.Label>{t("Threshold")}</Form.Label>
      <Form.Control onKeyDown={inputRandEl}  value={randEl} onChange={(e)=>setRandEl(e.target.value)} type="search" placeholder={t("change the expected percentage")} />
    </Form.Group></Container>
    




</Container></div>
  )
}
