import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Table } from 'react-bootstrap'
import './pdfprint.css'
import $ from 'jquery'
import { useTranslation } from 'react-i18next';

// import { LogicTable } from './LogicTable'

const to_number=(stri)=>{
    if (typeof stri=='string'){
        return Number(stri.replace(",", "."))

    }
    else{
        return Number(stri)

    } 

    
}

export const PdfToPrint = React.forwardRef(({selectedUses,selectedComps,selectedConsti,restElements,fullList,title,setTitle,finalConst,setFinalConst,finalTrans},ref) => {
    const { t, i18n } = useTranslation("pdf");
    // useEffect(() => {
    //     console.log('test',finalConst)
    //     console.log('test2',fullDiluent)
    //     console.log('selected const',selectedUses)
    //     console.log('finalTrans',finalTrans)
    // },[finalConst,fullList,selectedConsti,finalTrans])

    const [descriptBool,setDescriptBool]=useState([])
    useEffect(()=>{
        // console.log('final',finalConst)
        
        const act=finalConst.map(el=>{return({
            casio:el.ifraElement.cas_number,
            active:(descriptBool.find(x => x.casio === el.ifraElement.cas_number)==null ? null :descriptBool.find(x => x.casio === el.ifraElement.cas_number).active),
            super:el})})
        // console.log('activation')
        // console.log(selectedUses.length==0)
        setDescriptBool([...act])
        // console.log('asdasdas',descriptBool)

    },[finalConst])

    function minForPR(cas){
        if (cas.includes('494-40-6')){
            return 0.01
    
        }
        else if (cas.includes('140-29-4')){
            return 0.01
    
        }
        else if (cas.includes('78-59-1')){
            return 0.0013
        }
        else if (cas.includes('531-59-9')){
            return  0.01
        }
        else if (cas.includes('94-59-7')){
            return 0.01
        }
      }

    const search_min_cat = (element) => {
        const posibleMins=selectedUses.map(use=>{return to_number(element[use.category])})
        const min = Math.min(...posibleMins);
        const index = posibleMins.indexOf(min);
        const category = (index === -1 ? "See Notebox" : selectedUses[index].category)  
        // return Math.min(...posibleMins)
        return {min:(index === -1 ? 'See Notebox' : min),category:category}
    }

    const colorRow=(l)=>{
        const bool=((search_min_cat(l.super.ifraElement).min>l.super.fullPercent*100 || search_min_cat(l.super.ifraElement).min=='See Notebox')? 'warning': 'danger')
        if (l.super.ifraElement.ifra_type=='PROHIBITION'){
            return 'danger'
        }
        if (l.super.ifraElement.ifra_type=='PROHIBITION_RESTRICTION'){
            if (l.super.procedence.includes("Element")){
                return 'danger'

            }
            else{
                return (minForPR(l.super.ifraElement.cas_number)>l.super.fullPercent*100 ? 'warning': 'danger')

            }
           
        }
        if (l.super.ifraElement.ifra_type=='PROHIBITION_RESTRICTION_SPECIFICATION'){
            return bool
        }
        if ( l.super.ifraElement.ifra_type=='PROHIBITION_SPECIFICATION'){
            
            return 'danger'
        }
        if (l.super.ifraElement.ifra_type=='SPECIFICATION'){
            return 'warning'
        }
        if (l.super.ifraElement.ifra_type=='RESTRICTION_SPECIFICATION'){
            if (l.super.ifraElement.cas_number.includes('90028-68-5')){
                const minimum=search_min_cat(l.super.ifraElement).min
                const findo=finalConst.find(x => x.ifraElement.cas_number.includes('90028-67-4'))
                if (findo==null){
                    return (search_min_cat(l.super.ifraElement).min>l.super.fullPercent*100 ? 'warning': 'danger')
                }
                else {
                    return (search_min_cat(l.super.ifraElement).min>l.super.fullPercent*100+findo.fullPercent*100 ? 'warning': 'danger')

                }
            }
            if (l.super.ifraElement.cas_number.includes('90028-67-4')){
                const minimum=search_min_cat(l.super.ifraElement).min
                const findo=finalConst.find(x => x.ifraElement.cas_number.includes('90028-68-5'))
                
                if (findo==null){
                    return (search_min_cat(l.super.ifraElement).min>l.super.fullPercent*100 ? 'warning': 'danger')
                }
                else {
                    return (search_min_cat(l.super.ifraElement).min>l.super.fullPercent*100+findo.fullPercent*100 ? 'warning': 'danger')

                }
            }
            return bool
        }
        if (l.super.ifraElement.ifra_type=='RESTRICTION_PROHIBITION'){
            return bool
        }
        if (l.super.ifraElement.ifra_type=='RESTRICTION'){
            if (l.super.ifraElement.cas_number=='111-12-6'){
                const minimum=search_min_cat(l.super.ifraElement).min
                const findo=finalConst.find(x => x.ifraElement.cas_number === '111-80-8')
                if (findo==null){
                    return (search_min_cat(l.super.ifraElement).min>l.super.fullPercent*100 ? '': 'danger')
                }
                else {
                    return (search_min_cat(l.super.ifraElement).min>l.super.fullPercent*100+findo.fullPercent*100 ? '': 'warning')

                }
            }
            return (search_min_cat(l.super.ifraElement).min>l.super.fullPercent*100 ? '': 'danger')
        }
        if (search_min_cat(l.super.ifraElement).min>l.super.fullPercent*100){
            return (l.super.ifraElement.ifra_type=='RESTRICTION' ? '': 'warning')
        }
        else{
            return 'danger'
        }
    }

    const minNumber=(l)=>{
        if (l.super.ifraElement.ifra_type=='PROHIBITION_RESTRICTION' && l.super.procedence.includes("Element")==false){
            return minForPR(l.super.ifraElement.cas_number)

        }

        return (selectedUses.length==0 ? 'Use not selected' : search_min_cat(l.super.ifraElement).min)


    }
    const catBox=(l)=>{
        if (search_min_cat(l.super.ifraElement).category=='See Notebox'){
            return 'See Notebox'
        }
        else {
            return `${(selectedUses.find(x => x.category === search_min_cat(l.super.ifraElement).category).name)} (${search_min_cat(l.super.ifraElement).category})`
        }
        

    }
    const innerDil=(l)=>{
        let fullIfra=0
        for (var i of l.ifraElement.composition){
            fullIfra=fullIfra+to_number(i.concen_constituent)

        }
        const otherDil=100-fullIfra
        return (otherDil/100)*((to_number(l.percent)/100)*(to_number(l.intensity)/100)*100)
    }

    const [fullDiluent,setFullDiluent]=useState(0)
    const [seeDiluents,setSeeDiluents]=useState(false)
    useEffect(()=>{
        let dil=0
        for (var i of selectedConsti){
            dil=dil+(to_number(i.percent)/100)*(1-(to_number(i.intensity)/100))*100

        }
        for (var i of selectedComps){
            dil=dil+((to_number(i.percent)/100)*(1-(to_number(i.intensity)/100))*100+innerDil(i))

        }
        for (var i of restElements){
            dil=dil+(to_number(i.percent)/100)*(1-(to_number(i.intensity)/100))*100

        }
        setFullDiluent(dil)
    }
    ,[selectedComps,selectedConsti,restElements])

    const cat1 = [100]
    const cat2 = [100]
    const cat3 = [100]
    const cat4 = [100]
    const cat5a = [100]
    const cat5b = [100]
    const cat5c = [100]
    const cat5d = [100]
    const cat6 = [100]
    const cat7a = [100]
    const cat7b = [100]
    const cat8 = [100]
    const cat9 = [100]
    const cat10a = [100]
    const cat10b = [100]
    const cat11a = [100]
    const cat11b = [100]
    const cat12 = [100]

    const objcate = new Object()

    function rounder(num){
        return Math.round(num*100)/100
    }

    
    
    // useEffect(()=> {
    //     setfinalConstAdjust(finalConst.map(elem => {
    //         elem.category_1
    //     }))
    // })
    // console.log(finalConstAdjust)    

    // finalConst.map(elem => {
        
    // })
    
        

    

    // useEffect(() => {
    //     console.log('test',finalConst)
    //     console.log('selected const',selectedUses)
    // },[finalConst,fullList,selectedConsti])

    // $(document).ready(
    //     function() {
    //         $("footer").load("Disclaimer: This Certificate provides restrictions for use of the specified product based only on those materials restricted by IFRA Standards for the toxicity endpoint(s) described in each Standard. This Certificate does not provide certification of a comprehensive safety assessment of all product constituents. This certificate is the responsibility of the fragrance supplier issuing it. It has not been prepared or endorsed by IFRA in anyway.")

    //     }
    // )
    var d = new Date(); 
    const datetopost = d.getDay()+ '/'+ d.getMonth() + '/'+ d.getFullYear() 

    // $("#footer").load("Disclaimer: This Certificate provides restrictions for use of the specified product based only on those materials restricted by IFRA Standards for the toxicity endpoint(s) described in each Standard. This Certificate does not provide certification of a comprehensive safety assessment of all product constituents. This certificate is the responsibility of the fragrance supplier issuing it. It has not been prepared or endorsed by IFRA in anyway.")

    return (
        <div className='print-source' ref={ref} documentTitle={title}>
            <Container>
            {/* style={{width:'400px'}} */}
                <h1>{t("CERTIFICATE OF CONFORMITY OF FRAGRANCE MIXTURES WITH IFRA STANDARDS")}</h1>
                <h6 style={{textAlign: "center"}}>{t("This Certificate assesses the conformity of a fragrance mixture with IFRA Standards and provides restrictions for use as necessary. It is based only on those materials subject to IFRA Standards for the toxicity endpoint(s) described in each Standard.")} </h6>
                <hr></hr>
                <h5>{t("Certifying party: ")}</h5>
                <h6>{localStorage.company_name}</h6>
                <h6>{"Email: " + localStorage.email}</h6>
                {/* <h6>{t("Date: ") + datetopost}</h6> */}
                {/* <h4>{localStorage.username} </h4> */}
                {/* profile should be made */}
                <h5>{t("Scope of the certificate: ")}</h5>
                <p><em>{t("            Product: ") + title }</em></p>
                <hr></hr>
                <p>{t("Categories where the product will be applied:")}</p>
                {selectedUses.length == 0 ? <li>{t("Non use specified")}</li>:<></>}
                <p>{selectedUses.map((e)=> <li>{t(e.category) + ' - ' + t(e.name) + '\n'}</li>)}</p>
                {descriptBool.map((l) => {if (l.super.ifraElement.ifra_type == "RESTRICTION" || l.super.ifraElement.ifra_type == 'PROHIBITION_RESTRICTION_SPECIFICATION' || l.super.ifraElement.ifra_type == 'RESTRICTION_PROHIBITION' || l.super.ifraElement.ifra_type =='RESTRICTION_SPECIFICATION'){
                    

                    cat1.push(Math.min((l.super.ifraElement.category_1/(l.super.fullPercent*100))*100,100))
                    cat2.push(Math.min((l.super.ifraElement.category_2/(l.super.fullPercent*100))*100,100))
                    cat3.push(Math.min((l.super.ifraElement.category_3/(l.super.fullPercent*100))*100,100))
                    cat4.push(Math.min((l.super.ifraElement.category_4/(l.super.fullPercent*100))*100,100))
                    cat5a.push(Math.min((l.super.ifraElement.category_5a/(l.super.fullPercent*100))*100,100))
                    cat5b.push(Math.min((l.super.ifraElement.category_5b/(l.super.fullPercent*100))*100,100))
                    cat5c.push(Math.min((l.super.ifraElement.category_5c/(l.super.fullPercent*100))*100,100))
                    cat5d.push(Math.min((l.super.ifraElement.category_5d/(l.super.fullPercent*100))*100,100))
                    cat6.push(Math.min((l.super.ifraElement.category_6/(l.super.fullPercent*100))*100,100))
                    cat7a.push(Math.min((l.super.ifraElement.category_7a/(l.super.fullPercent*100))*100,100))
                    cat7b.push(Math.min((l.super.ifraElement.category_7b/(l.super.fullPercent*100))*100,100))
                    cat8.push(Math.min((l.super.ifraElement.category_8/(l.super.fullPercent*100))*100,100))
                    cat9.push(Math.min((l.super.ifraElement.category_9/(l.super.fullPercent*100))*100,100))
                    cat10a.push(Math.min((l.super.ifraElement.category_10a/(l.super.fullPercent*100))*100,100))
                    cat10b.push(Math.min((l.super.ifraElement.category_10b/(l.super.fullPercent*100))*100,100))
                    cat11a.push(Math.min((l.super.ifraElement.category_11a/(l.super.fullPercent*100))*100,100))
                    cat11b.push(Math.min((l.super.ifraElement.category_11b/(l.super.fullPercent*100))*100,100))
                    cat12.push(Math.min((l.super.ifraElement.category_12/(l.super.fullPercent*100))*100,100))

                    





                    objcate.category_1 = Math.min(...cat1)
                    objcate.category_2 = Math.min(...cat2)
                    objcate.category_3 = Math.min(...cat3)
                    objcate.category_4 = Math.min(...cat4)
                    objcate.category_5a = Math.min(...cat5a)
                    objcate.category_5b = Math.min(...cat5b)
                    objcate.category_5c = Math.min(...cat5c)
                    objcate.category_5d = Math.min(...cat5d)
                    objcate.category_6 = Math.min(...cat6)
                    objcate.category_7a =Math.min(...cat7a)
                    objcate.category_7b = Math.min(...cat7b)
                    objcate.category_8 = Math.min(...cat8)
                    objcate.category_9 = Math.min(...cat9)
                    objcate.category_10a = Math.min(...cat10a)
                    objcate.category_10b = Math.min(...cat10b)
                    objcate.category_11a = Math.min(...cat11a)
                    objcate.category_11b = Math.min(...cat11b)
                    objcate.category_12 = Math.min(...cat12)

                    // console.log('cat1 print',cat1)

                }
                })}
                <p>{t("We certify that the above mixture is in compliance with the Standards of the INTERNATIONAL FRAGRANCE ASSOCIATION (IFRA), up to and including the 51 Amendment to the IFRA Standards, provided it is used in the following category(ies) at a maximum concentration level of:")}</p>
                <Table bordered striped>
                    <thead>
                        <tr>
                            <th>{t("IFRA Category(ies) [see Table 12 in Guidance for the use of IFRA Standards for details]")}</th>
                            <th>{t("Maximum level (%)")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{t("Category 1")}</td>
                            <td>{isNaN(rounder(objcate.category_1)) ? 100 : rounder(objcate.category_1)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 2")}</td>
                            <td>{isNaN(rounder(objcate.category_2)) ? 100 : rounder(objcate.category_2)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 3")}</td>
                            <td>{isNaN(rounder(objcate.category_3)) ? 100 : rounder(objcate.category_3)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 4")}</td>
                            <td>{isNaN(rounder(objcate.category_4)) ? 100 : rounder(objcate.category_4)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 5a")}</td>
                            <td>{isNaN(rounder(objcate.category_5a)) ? 100 : rounder(objcate.category_5a)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 5b")}</td>
                            <td>{isNaN(rounder(objcate.category_5b)) ? 100 : rounder(objcate.category_5b)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 5c")}</td>
                            <td>{isNaN(rounder(objcate.category_5c)) ? 100 : rounder(objcate.category_5c)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 5d")}</td>
                            <td>{isNaN(rounder(objcate.category_5d)) ? 100 : rounder(objcate.category_5d)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 6")}</td>
                            <td>{isNaN(rounder(objcate.category_6)) ? 100 : rounder(objcate.category_6)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 7a")}</td>
                            <td>{isNaN(rounder(objcate.category_7a)) ? 100 : rounder(objcate.category_7a)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 7b")}</td>
                            <td>{isNaN(rounder(objcate.category_7b)) ? 100 : rounder(objcate.category_7b)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 8")}</td>
                            <td>{isNaN(rounder(objcate.category_8)) ? 100 : rounder(objcate.category_8)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 9")}</td>
                            <td>{isNaN(rounder(objcate.category_9)) ? 100 : rounder(objcate.category_9)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 10a")}</td>
                            <td>{isNaN(rounder(objcate.category_10a)) ? 100 : rounder(objcate.category_10a)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 10b")}</td>
                            <td>{isNaN(rounder(objcate.category_10b)) ? 100 : rounder(objcate.category_10b)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 11a")}</td>
                            <td>{isNaN(rounder(objcate.category_11a)) ? 100 : rounder(objcate.category_11a)}</td>
                        </tr>
                        <tr>
                            <td>{t("Category 11b")}</td>
                            <td>{isNaN(rounder(objcate.category_11b)) ? 100 : rounder(objcate.category_11b)}</td>
                        </tr>
                        <tr>
                            <td>{("Category 12")}</td>
                            <td>{isNaN(rounder(objcate.category_12)) ? 100 : rounder(objcate.category_12)}</td>
                        </tr>
                    </tbody>
                </Table>
                <br></br>
                <p>{t('Information about presence and concentration of fragrance ingredients subject to IFRA Standards in the fragrance mixture ') + title + t(' is as follows: ')}</p>
                <hr></hr>
                <Table bordered striped>
                            <thead>
                                <tr>
                                    <th>{t("IFRA Standard")}</th>
                                    <th>Nº CAS</th>
                                    <th>{t("Ingredient Name")}</th>
                                    <th>{t("Procedence")}</th>
                                    <th>{t("Category applied of max restriction")}</th>
                                    <th>{t("Max Ifra")}</th>
                                    <th>{t("% on Product")}</th>
                                </tr>
                            </thead>
                {descriptBool.map((l) => {

                    return (
                        <tbody>

                        
                            <tr>
                                <td>{t(l.super.ifraElement.ifra_type)}</td>
                                <td>{l.casio}</td>
                                <td>{l.super.ifraElement.name_ifra }</td>
                                {/* + '( ' + l.super.ifraElement.synonyms +' )' */}
                                <td>{t(l.super.procedence)}</td>
                                <td>{(selectedUses.length==0 ? t('Use not selected') : t(catBox(l)))}</td>
                                <td>{minNumber(l)}</td>
                                <td>{rounder(l.super.fullPercent*100)}</td>
                            </tr>
                            <tr>
                                {/* <td></td> */}
                                <td colSpan={7}>{l.super.ifraElement.specified_notes ?  l.super.ifraElement.specified_notes : [l.super].map((e)=>{
                                    

                                    const keys = Object.keys(e).slice(-18,);
                                    const values = Object.values(e).slice(-18,)
                                    
                                        return (
                                            <div>
                                                <Table striped bordered hover>
                                                    <thead>
                                                            <th>CAT. 1:</th>
                                                            <th>CAT. 2:</th>
                                                            <th>CAT. 3:</th>
                                                            <th>CAT. 4:</th>
                                                            <th>CAT. 5A:</th>
                                                            <th>CAT. 5B:</th>
                                                            <th>CAT. 5C:</th>
                                                            <th>CAT. 5D:</th>
                                                            <th>CAT. 6:</th>
                                                    </thead>
                                                    <tbody>
                                            
                                                            <td>{e.ifraElement.category_1 ? rounder(to_number(Math.min(((e.ifraElement.category_1)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_2 ? rounder(to_number(Math.min(((e.ifraElement.category_2)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_3 ? rounder(to_number(Math.min(((e.ifraElement.category_3)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_4 ? rounder(to_number(Math.min(((e.ifraElement.category_4)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_5a ?rounder(to_number(Math.min(((e.ifraElement.category_5a)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_5b ?rounder(to_number(Math.min(((e.ifraElement.category_5b)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_5c ?rounder(to_number(Math.min(((e.ifraElement.category_5c)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_5d ?rounder(to_number(Math.min(((e.ifraElement.category_5d)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_6 ? rounder(to_number(Math.min(((e.ifraElement.category_6)/(e.fullPercent*100))*100,100))): to_number(0)}</td>
                                                    </tbody>
                                                    <thead>
                                                            <th>CAT. 7.A:</th>
                                                            <th>CAT. 7.B:</th>
                                                            <th>CAT. 8:</th>
                                                            <th>CAT. 9:</th>
                                                            <th>CAT. 10.A:</th>
                                                            <th>CAT. 10.B:</th>
                                                            <th>CAT. 11.A:</th>
                                                            <th>CAT. 11.B:</th>
                                                            <th>CAT. 12:</th>
                                                    </thead>
                                                    <tbody>
                                            
                                                            <td>{e.ifraElement.category_7a ? rounder(to_number(Math.min(((e.ifraElement.category_7a)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_7b ? rounder(to_number(Math.min(((e.ifraElement.category_7b)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_8 ? rounder(to_number(Math.min(((e.ifraElement.category_8)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_9 ? rounder(to_number(Math.min(((e.ifraElement.category_9)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_10a ? rounder(to_number(Math.min(((e.ifraElement.category_10a)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_10b ? rounder(to_number(Math.min(((e.ifraElement.category_10b)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_11a ? rounder(to_number(Math.min(((e.ifraElement.category_11a)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_11b ? rounder(to_number(Math.min(((e.ifraElement.category_11b)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_12 ? rounder(to_number(Math.min(((e.ifraElement.category_12)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                    </tbody>
                                                </Table >
                                                {/* <tr>{keys.forEach((x, i) => <td>{x}</td>)}</tr>
                                                <tr><td>{Object.values(e).slice(-18,-10)}</td></tr>
                                                <tr><td>{Object.keys(e).slice(-10,)}</td></tr>
                                                <tr><td>{Object.values(e).slice(-10,)}</td></tr> */}
                                            </div> 
                                        )
                                })}</td> </tr>
                                
                                {(l.super.ifraElement.ifra_type == 'RESTRICTION_SPECIFICATION' || l.super.ifraElement.ifra_type == 'PROHIBITION_RESTRICTION_SPECIFICATION' || l.super.ifraElement.ifra_type == "RESTRICTION_PROHIBITION")? 
                                <tr>
                                    <td colSpan={7}> 
                                        {[l.super].map((e)=>{
                                    // console.log(e[0])
                                    const keys = Object.keys(e).slice(-18,);
                                    const values = Object.values(e).slice(-18,);
                                    
                                        return (
                                            <div>
                                                <Table striped bordered hover>
                                                    <thead>
                                                            <th>CAT. 1:</th>
                                                            <th>CAT. 2:</th>
                                                            <th>CAT. 3:</th>
                                                            <th>CAT. 4:</th>
                                                            <th>CAT. 5A:</th>
                                                            <th>CAT. 5B:</th>
                                                            <th>CAT. 5C:</th>
                                                            <th>CAT. 5D:</th>
                                                            <th>CAT. 6:</th>
                                                    </thead>
                                                    <tbody>
                                            
                                                            <td>{e.ifraElement.category_1 ? rounder(to_number(Math.min(((e.ifraElement.category_1)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_2 ? rounder(to_number(Math.min(((e.ifraElement.category_2)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_3 ? rounder(to_number(Math.min(((e.ifraElement.category_3)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_4 ? rounder(to_number(Math.min(((e.ifraElement.category_4)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_5a ?rounder(to_number(Math.min(((e.ifraElement.category_5a)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_5b ?rounder(to_number(Math.min(((e.ifraElement.category_5b)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_5c ?rounder(to_number(Math.min(((e.ifraElement.category_5c)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_5d ?rounder(to_number(Math.min(((e.ifraElement.category_5d)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_6 ? rounder(to_number(Math.min(((e.ifraElement.category_6)/(e.fullPercent*100))*100,100))): to_number(0)}</td>
                                                    </tbody>
                                                    <thead>
                                                            <th>CAT. 7.A:</th>
                                                            <th>CAT. 7.B:</th>
                                                            <th>CAT. 8:</th>
                                                            <th>CAT. 9:</th>
                                                            <th>CAT. 10.A:</th>
                                                            <th>CAT. 10.B:</th>
                                                            <th>CAT. 11.A:</th>
                                                            <th>CAT. 11.B:</th>
                                                            <th>CAT. 12:</th>
                                                    </thead>
                                                    <tbody>
                                            
                                                            <td>{e.ifraElement.category_7a ? rounder(to_number(Math.min(((e.ifraElement.category_7a)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_7b ? rounder(to_number(Math.min(((e.ifraElement.category_7b)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_8 ? rounder(to_number(Math.min(((e.ifraElement.category_8)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_9 ? rounder(to_number(Math.min(((e.ifraElement.category_9)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_10a ? rounder(to_number(Math.min(((e.ifraElement.category_10a)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_10b ? rounder(to_number(Math.min(((e.ifraElement.category_10b)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_11a ? rounder(to_number(Math.min(((e.ifraElement.category_11a)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_11b ? rounder(to_number(Math.min(((e.ifraElement.category_11b)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                            <td>{e.ifraElement.category_12 ? rounder(to_number(Math.min(((e.ifraElement.category_12)/(e.fullPercent*100))*100,100))) : to_number(0)}</td>
                                                    </tbody>
                                                </Table >
                                                {/* <tr>{keys.forEach((x, i) => <td>{x}</td>)}</tr>
                                                <tr><td>{Object.values(e).slice(-18,-10)}</td></tr>
                                                <tr><td>{Object.keys(e).slice(-10,)}</td></tr>
                                                <tr><td>{Object.values(e).slice(-10,)}</td></tr> */}
                                            </div> 
                                        )
                                }) }
                                    </td>
                                
                                </tr>:null}
                                {/* l.super.ifraElement.restricted_notes || l.super.ifraElement.prohibit_notes || */}
                            
                        </tbody>
                    )})}
                    {
                        finalTrans.map((e) => {
                            return (
                                <tbody>
        
                                
                                    <tr>
                                        <td>{t("No Restriction (Transparency List)")}</td>
                                        <td>{e.ifraElement.cas}</td>
                                        <td>{e.ifraElement.name}</td>
                                        {/* + '( ' + l.super.ifraElement.synonyms +' )' */}
                                        <td>{t(e.procedence)}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{rounder(e.fullPercent*100)}</td>
                                    </tr>
                                </tbody>)
                            

                        })
                    }

                </Table>
                
                <br></br>


                {/* {restElements.length == 0 ? null :
                <div className="page-break">

                
                <p>Para maxima transparencia, se detallan aquellos elementos utilizados pero no alcanzados por IFRA:</p>
                <Table>
                    <thead>
                        <th>Nombre</th>
                        <th>% en composicion</th>
                    </thead>
                    {restElements.length == 0 ? null :
                    restElements.map((e) => {
                        return (
                            <tr>
                                <td>
                                    {e.name}
                                </td>
                                <td>
                                    {(Number(e.intensity/100)*Number(e.percent/100))*100}
                                </td>
                            </tr>
                        )
                    })}
                </Table>
                    </div>} */}

                
                <br></br>
                <div className="page-break">
                    <p>{t("APPENDICE 1: DEFINITION OF THE CATEGORIES IN IFRA")}</p>
                    <Table>
                        <thead>
                            <th>{t("Type of final product")}</th>
                            <th>{t("IFRA Category")}</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{t("Products applied to the lips")}</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>{t("Products applied to the axillae")}</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>{t("Products applied to the face/body using fingertips")}</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>{t("Products related to fine fragrance")}</td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>{t("Products applied to the body using the hands(palms), primarily leave-on(Body lotion)")}</td>
                                <td>5A</td>
                            </tr>
                            <tr>
                                <td>{t("Products applied to the face using the hands(palms), primarily leave-on(Face moisturizer)")}</td>
                                <td>5B</td>
                            </tr>
                            <tr>
                                <td>{t("Products applied to the hands using the hands(palms), primarily leave-on(Hand cream)")}</td>
                                <td>5C</td>
                            </tr>
                            <tr>
                                <td>{t("Products applied to babies using the hands(palms), primarily leave-on(Baby cream, oil, talc)")}</td>
                                <td>5D</td>
                            </tr>
                            <tr>
                                <td>{t("Products with oral and lip exposure")}</td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>{t("Rinse-off products applied to the hair with some hand contact")}</td>
                                <td>7A</td>
                            </tr>
                            <tr>
                                <td>{t("Leave-on products applied to the hair with some hand contact")}</td>
                                <td>7B</td>
                            </tr>
                            <tr>
                                <td>{t("Products with significant anogenital exposure (tampon)")}</td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>{t("Products with body and hand exposure, primarily rinse off")}</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>{t("Household care excluding aerosol products (excluding aerosol/spray products)")}</td>
                                <td>10A</td>
                            </tr>
                            <tr>
                                <td>{t("Household care products with mostly hand contact (household aerosol/spray products)")}</td>
                                <td>10B</td>
                            </tr>
                            <tr>
                                <td>{t("Products with intended skin contact but minimal transfer of fragrance to skin from inert substrate without UV exposure")}</td>
                                <td>11A</td>
                            </tr>
                            <tr>
                                <td>{t("Products with intended skin contact but minimal transfer of fragrance to skin from inert substrate with potential UV exposure")}</td>
                                <td>11B</td>
                            </tr>
                            <tr>
                                <td>{t("Other air care products not intended for direct skin contact, minimal or insignificant transfer to skin")}</td>
                                <td>12</td>
                            </tr>
                        </tbody>
                    </Table>

                </div>
                
                <div className="page-footer">
                    <footer>
                        <p>{t("Disclaimer: This Certificate provides restrictions for use of the specified product based only on those materials restricted by IFRA Standards for the toxicity endpoint(s) described in each Standard. This Certificate does not provide certification of a comprehensive safety assessment of all product constituents. This certificate is the responsibility of the fragrance supplier issuing it. It has not been prepared or endorsed by IFRA in anyway. ")}</p>
                    </footer>

                </div>


                {/* {$(document).ready(
                    function(){
                        $("#footer").load(<p>"Disclaimer: This Certificate provides restrictions for use of the specified product based only on those materials restricted by IFRA Standards for the toxicity endpoint(s) described in each Standard. This Certificate does not provide certification of a comprehensive safety assessment of all product constituents. This certificate is the responsibility of the fragrance supplier issuing it. It has not been prepared or endorsed by IFRA in anyway."</p>)
                    }
                )} */}
                {/* {$("#footer").on('load', function () { <p>"Disclaimer: This Certificate provides restrictions for use of the specified product based only on those materials restricted by IFRA Standards for the toxicity endpoint(s) described in each Standard. This Certificate does not provide certification of a comprehensive safety assessment of all product constituents. This certificate is the responsibility of the fragrance supplier issuing it. It has not been prepared or endorsed by IFRA in anyway."</p> })} */}
                {/* <script>
                    {
                        $(document).ready(
                            function() {
                                $("#footer").load("Disclaimer: This Certificate provides restrictions for use of the specified product based only on those materials restricted by IFRA Standards for the toxicity endpoint(s) described in each Standard. This Certificate does not provide certification of a comprehensive safety assessment of all product constituents. This certificate is the responsibility of the fragrance supplier issuing it. It has not been prepared or endorsed by IFRA in anyway.")

                            }
                        )
                    }

                </script> */}
                

                

                

                
            </Container>
            

            
        </div>


    )
}) 