import React, { useState,useEffect } from 'react'
import { FormulaTable } from './formulas/FormulaTable'
import { SearchFormula } from './formulas/SearchFormula'
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './protectedRoute/axios'
import { CardsFormulas } from './formulas/CardsFormulas';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { cifrar,descifrar } from './protectedRoute/encryption';
import ReactGA from 'react-ga4';


export const Formulas = () => {
  // ReactGA.send({ hitType: "pageview", page: window.location.pathname})
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('formulas');
  // const [token] = useCookies(['access_token','refresh_token','username'])
  // useEffect(()=>{
  //   if (!token['access_token']){
  //   navigate('/')}}
  //   ,[token])
  // const [token] = useEffect(()=>{return [localStorage.getItem('access_token'),localStorage.getItem('refresh_token'),localStorage.getItem('username')],localStorage})
  useEffect(()=>{
     if (!descifrar(localStorage.getItem('access_token'))){
     navigate('/')}}
     ,[descifrar(localStorage.getItem('access_token'))])


  //FETCHEO
  const [elements,setElements]=useState([])
  const [compounds,setCompounds]=useState([])

  useEffect(()=> {
    async function fetchData(){
      const request = await axiosInstance.get('/element/')
      setElements(request.data)
      const request2 = await axiosInstance.get('/compuestos/')
      
      let result = request2.data
      
      const groupByCategory = result.reduce((group, product) => {
        const { ncs_name } = product;
        group[ncs_name] = group[ncs_name] ?? [];
        group[ncs_name].push(product);
        return group;
      }, {});
      let finalConst=[]
      for (const item in groupByCategory) {
        finalConst.push({
          ncs_name:item,
          CAS:groupByCategory[item][0].CAS,
          composition:groupByCategory[item],
          ncs_botanic_name:groupByCategory[item][0].ncs_botanic_name,
          other_CAS:groupByCategory[item][0].other_CAS,
          id:item
        })
        
      }
      //console.log(groupByCategory)
      //console.log(request2.data)
      // console.log(finalConst)
      //setCompounds(request2.data)
      setCompounds(finalConst)
    }
    fetchData()
    
  }
  ,[])

  const [form_views,setFormView] = useState([])

  // useEffect(() => {
  //   axiosInstance.get('/user/auth/user/').then(function (response) {console.log(response.data.id)
  //     axiosInstance.get('formulas-overview/',{ params: { user: response.data.id } }).then(function (response) {
  //       // console.log(response.data)
  //       let fomulas_overview_response = response.data
  //       console.log('asdasdasdasdasd 1!',fomulas_overview_response)
  //       // updateForm_views(form_views => ({...form_views,fomulas_overview_response}))
  //       updateForm_views(response.data)
  //       console.log('asdasdasdasdasd',form_views)
  //     })
  //   })
  // },[])

  useEffect(()=> {
    async function fetchData(){
      const request = await axiosInstance.get('/user/auth/user/')
      const request2  = await axiosInstance.get('formulas-overview/',{ params: { user: request.data.id } })
      setFormView(request2.data)
    }
    fetchData()},[2])

  // useEffect(() => {
  //   console.log('adsasdasd',form_views)
  // },[form_views])


  const [formulaSeleccionada,setFormulaSeleccionada]=useState({})

  
  
  return (
    <div><br/><br/><br></br><br></br>
    {(Object.keys(formulaSeleccionada).length==0?<></>:<SearchFormula fullList={elements} fullListComps={compounds} {...{setFormulaSeleccionada,formulaSeleccionada,elements}}/>)}
     
    {/* <br/><hr/><hr/><br/> */}
    <h1 style={{textAlign:'center'}}>{t('Saved Formulas:')}</h1>
    

    {/* <FormulaTable variant='secondary'/> */}
    <CardsFormulas {...{form_views,setFormView,setFormulaSeleccionada,formulaSeleccionada}} variant='secondary'/>

      
      
    </div>
  )
}
