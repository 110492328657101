import React from 'react'

function SignIn(props) {
    const { children } = props;
  return (
    <div className='sign-in'>
        <div className='sign-in-1'>
            {children}
        </div>
    </div>
  )
}

export default SignIn