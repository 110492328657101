import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './landing/terms.css'

export default function Terms() {
    const { t, i18n } = useTranslation("terms");
  return (
    <div id='tomob'>
        
        <Container>
            <div className='PrivacyPolicy' style={{color:"#0a3a62"}}>
            <h1>{t("Privacy Policy")}</h1>
            <p>{t("Remember that before starting to use any of the services or functionalities of www.innorma.eu (hereinafter, the WEB) you must read this Privacy and Cookies Policy, as well as the General Conditions of Use. In this section you can check , of the existence of any particular use condition or the specific treatment of any of your Personal Data.")}</p>
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>{t("Responsible for the data")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            {t("The person responsible for processing the personal data necessary to provide the services offered on www.innorma.eu is:")}
            <ul>
                <li>Jonatan Bella & Agustín Bustos Barton</li>
                <li>{t("Adress: Alcolea 89, 2-2, Barcelona, Spain - 08014")}</li>
            </ul>
            <p>{t("The certificates provided are based only on materials restricted by IFRA standards for toxicity endpoints. IFRA does not provide certification of a comprehensive safety assessment of all product constituents and they are responsability of the fragance supplier issuing it.")}</p>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>{t("Purposes and legitimation of data processing")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>{t("Where we collect the data")}</TableCell>
                    <TableCell>{t("Used data")}</TableCell>
                    <TableCell>{t("Purpose and legal basis")}</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                <TableRow>
                    <TableCell>{t("Contact Form")}</TableCell>
                    <TableCell>{t("Name, Email, Phone Number")}</TableCell>
                    <TableCell>{t("To give answer to the application made. The legal basis is the consent granted by the user when making their query.")}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{t("User registration")}</TableCell>
                    <TableCell>{t("Name, Email, Company Name, Password")}</TableCell>
                    <TableCell>{t("Needed to provide the services to the user, as access to his formulas and out creation system. The legal basis is the consent of the interested party. Likewise, provision of the service and billing whose legal basis is the contractual relationship.")} </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{t("Saving Formulas")}</TableCell>
                    <TableCell>{t("Formula Composition")}</TableCell>
                    <TableCell>{t("We save the respective formula composition of the user with the objective to provide them services such as certification, calculation and processing of data.The legal basis is the consent of the interested party.")} </TableCell>
                </TableRow>
                </TableBody>
            </Table>
            </TableContainer>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>{t("Data collection and duration")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                {t("The recopilation of data on the web is done through:")}
                <ul>
                    <li>
                        {t("Contact Form")}
                    </li>
                    <li>
                        {t("User registration")}
                    </li>
                    <li>
                        {t("Saving Formulas")}
                    </li>
                </ul>
                {t("The data provided by the user will be kept by Jonatan Bella and Agustín Bustos Barton (hereinafter, Innorma). during the period of time necessary to attend to your query or as long as you do not revoke your consent. In any case, we may keep your personal data, duly blocked, for the attention of judicial, administrative or fiscal claims, for the periods legally determined by each applicable regulation.")}
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>{t("Personal Data and Conservation Procedure")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        {t("Conservation of personal data")}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t("Among the principles contemplated in the General Data Protection Regulation (hereinafter “GDPR”) is the limitation of the retention period, by virtue of which personal data must be kept in a way that allows the identification of the interested parties. for no longer than is necessary for the purposes of the processing of personal data. The time of conservation of the data must always be the minimum possible. As a consequence of this principle, Innorma is obliged to define the retention periods of personal data, and to delete them when they are no longer useful for the purpose for which they were initially collected. Consequently, this data conservation and deletion procedure is elaborated in order to document the periods of conservation of personal data, which must be known by all Innorma personnel, as well as the destruction mechanisms used. Once the purposes for which the personal data were collected have been fulfilled, they can only continue to be kept when certain requirements are met: ")}
                            <ul>
                                <li>{t("When they are stored for archival purposes in the public interest, for scientific or historical research purposes or for statistical purposes;")}</li>
                                <li>{t("When a law establishes the specific obligation of conservation or;")}</li>
                                <li>{t("Duly blocked, when responsibility for the treatment could be derived from the person in charge.")}</li>
                            </ul>
                            <h5>{t("Relationship with the exercise of the right of suppression.")}</h5>
                            <p>{t("The right of deletion allows interested parties to request the deletion of their personal data under certain requirements, however, there are cases in which the interested party will not have the right to delete their data, and, therefore, Innorma may keep the data personal, even if these were no longer necessary for the initial purpose of the treatment. These assumptions are the following:")}</p>
                            <ul>
                                <li>{t("When necessary to exercise the right to freedom of expression and information;")}</li>
                                <li>{t("For the fulfillment of a legal obligation or a mission carried out in the public interest;")}</li>
                                <li>{t("For reasons of public interest in the field of public health;")}</li>
                                <li>{t("For archival purposes in the public interest, scientific or historical research purposes or statistical purposes and;")}</li>
                                <li>{t("For the formulation, exercise or defense of claims.")}</li>
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        {t("Conservation by the person in charge of the treatment")}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {t("When Innorma has contracted the services of a person in charge of the treatment, or the same acts as such, it must be taken into account that in the contract signed for this purpose, the destination of the data must have been determined once the provision of the services has been completed.However, the person in charge may keep the personal data as long as there are specific legal obligations or legal responsibilities could be derived from said data processing. ")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        {t("Blocking of personal data.")}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <p>{t("When the personal data initially collected for a specific purpose are no longer necessary to achieve the objective, they must be deleted. In this sense, Innorma must, both when the deletion of the personal data is appropriate, and when the rectification of these is appropriate, keep the data blocked except for making it available to the Public Administrations, Judges and Courts, for the attention of the possible responsibilities arising from the treatment during the term of prescription of these. Once these deadlines have been fulfilled, Innorma must proceed to the definitive deletion of the personal data, establishing the corresponding security measures that prevent its treatment and visualization, unless there is a legal requirement for it or it is necessary to access the data to determine responsibilities derived from the treatment. of these.")}</p>
                            <h6>{t("Deadlines")}</h6>
                            <p>{t("The determination of the conservation periods will be stipulated in attention to the prescription periods of the possible responsibilities arising from the treatment. For this, the deadlines set forth in different sectoral and specific regulations must be taken into account. In this sense, in the event that one or more blocking periods are applicable for the same data processing, those with the longest duration will prevail, not proceeding to eliminate them until the legal retention period has elapsed. To know exactly what the conservation periods are, in this procedure we introduce Annex I in which we reflect the different data conservation periods according to the type of treatment that is carried out.")}</p>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        {t("Destruction mechanisms")}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <p>{t("Regardless of the format in which they are found, destroying personal data consists of eliminating all the files that we have in paper and digital format, so that they cannot be recovered. This destruction implies that it would not be enough to send this data to the recycle bin, if it were in digital format; or simply tear it up and throw it in the bin if they are in paper format. Once the conservation and blocking periods have elapsed, the data must be deleted and the information systems through which they are being processed and stored must be evaluated. Once the certain data conservation and blocking periods are known by the organizations, Innorma has established the necessary notification and alert measures so that it is known at all times when the blocking periods elapse and, therefore, the elimination of data must be carried out. the data, without prejudice to the possibility of proceeding to a specific deletion of these derived from a specific circumstance whose deletion must be previously assessed. Faced with this regulatory requirement and guaranteeing that documents are erased and destroyed correctly, Innorma has the following document destruction systems in paper format: ")}</p>
                            <ul>
                                <li>{t("Outsourcing of a specialized company")}</li>
                            </ul>
                            <p>{t("The destruction of personal data entrusted to third parties must be carried out adopting the necessary guarantees to ensure confidentiality in the deletion of data. Thus, in the case of choosing a third party, it must guarantee that a confidential destruction of the documentation takes place, whether in physical or logical support. In addition, the corresponding processing order contract must be signed with this third-party service provider, which includes the security measures that must be established to ensure the confidentiality and elimination of the information. A certificate accrediting safe destruction must also be required, stating, at a minimum, the reference of the support from which the information has been destroyed, the date, the method used and the signature or seal of the company that carries it out.")}</p>
                            <ul>
                                <li>{t("Destruction of documents with machines to destroy paper.")}</li>
                            </ul>
                            <p>{t("Innorma has paper destruction machines in its offices.")}</p>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        {t("Annex I. Conservation terms")}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>{t("Subject")}</TableCell>
                                <TableCell>{t("Personal Data")}</TableCell>
                                <TableCell>{t("Initial Date")}</TableCell>
                                <TableCell>{t("Deadline")}</TableCell>
                                <TableCell>{t("Legal Basis")}</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            <TableRow>
                                <TableCell>{t("Data Protection")}</TableCell>
                                <TableCell>
                                    <p>{t("Infraction Prescription")}</p>
                                    <ul>
                                    <li>{t("Very Serious - 3 years")}</li>
                                    <li>{t("Serious - 2 years")}</li>
                                    <li>{t("Mild - 1 year")}</li>
                                    </ul>
                                    </TableCell>
                                <TableCell>{t("From the recognition of the infraction by the Control Authority")}</TableCell>
                                <TableCell>{t("3 Years")}</TableCell>
                                <TableCell>{t("Articles 72.1, 73.1 and 74.1 of the Draft Organic Law on Data Protection")}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t("Personal civil actions")}</TableCell>
                                <TableCell>{t("Personal actions of all kinds that do not have a special limitation period set from when the obligation could be demanded, taking into account that in continuing obligations to do or not to do, the term will begin each time they are breached. Transitional regime: – Actions derived from legal relationships born between 7/10/2000 and 7/10/2005: statute of limitations of 15 years. – Actions derived from legal relationships born between 7/10/2005 and 7/10/2015: 5 years after the entry into force of the law, that is, 7/10/2020. – Actions derived from legal relationships born as of 7/10/2015: statute of limitations of five (5) years.")}</TableCell>
                                <TableCell>{t("The computation will start each time they are breached.")}</TableCell>
                                <TableCell>{t("5 years.")}</TableCell>
                                <TableCell>{t("- Article 1962 of the Civil Code and Article - 1939 of the Civil Code regarding the transitional regime.")}</TableCell>
                                {/* <TableCell>{t("Transitional regime:")}</TableCell> */}
                            </TableRow>
                            <TableRow>
                                <TableCell>{t("Documentation of a labor nature or related to social security")}</TableCell>
                                <TableCell>{t("Documentation, records or computer media in which the corresponding data have been transmitted that prove compliance with the obligations in terms of placement and employment, affiliation, registrations, cancellations or variations that, where appropriate, occurred in relation to said matters, as well as such as the contribution documents and supporting receipts for the payment of wages and the delegated payment of benefits. Add all the contractual documentation: RD 1424/2002, of December 27, which regulates the communication of the content of the contracts and their basic copies, does not establish anything in this regard, so we will analogously apply the previous precept.")}</TableCell>
                                <TableCell>{t("From the end of the employment relationship")}</TableCell>
                                <TableCell>{t("4 years.")}</TableCell>
                                <TableCell>{t("Article 21.1 of Royal Legislative Decree 5/2000, of August 4, approving the consolidated text of the Law on Offenses and Sanctions in the Social Order.")}</TableCell>
                                {/* <TableCell>{t("Transitional regime:")}</TableCell> */}
                            </TableRow>
                            <TableRow>
                                <TableCell>{t("Accounting and tax documentation")}</TableCell>
                                <TableCell>{t("For commercial purposes: Books, correspondence, documentation and supporting documents concerning your business, duly ordered, from the last entry made in the books, except as established by general or special provisions.This mercantile obligation extends both to the obligatory books (income, expenses, investment goods and provisions, in addition to the documentation and supporting documents that support the entries recorded in the books (invoices issued and received, tickets, corrective invoices, bank documents , etc.).")}</TableCell>
                                <TableCell>{t("Since the last accounting entry")}</TableCell>
                                <TableCell>{t("6 years.")}</TableCell>
                                <TableCell>{t("Art. 30 of the Royal Decree of August 22, 1885, by which the Commercial Code is published.")}</TableCell>
                                {/* <TableCell>{t("Transitional regime:")}</TableCell> */}
                            </TableRow>
                            <TableRow>
                                <TableCell>{t("Accounting and tax documentation")}</TableCell>
                                <TableCell>{t("For tax purposes: The accounting books and other mandatory record books according to the applicable tax regulations (IRPF, VAT, IS, etc.), as well as the documentary supports that justify the entries recorded in the books (including computer programs and files and any other supporting document that has fiscal importance), must be kept, at least, during the period in which the Administration has the right to verify and investigate and, consequently, to settle the tax debt.")}</TableCell>
                                <TableCell>{t("From the end of the fiscal year")}</TableCell>
                                <TableCell>{t("4 years.")}</TableCell>
                                <TableCell>{t("Section 3 (The prescription), Arts. 66 to 70 of Law 58/2003, of December 17, General Tax Law.")}</TableCell>
                                {/* <TableCell>{t("Transitional regime:")}</TableCell> */}
                            </TableRow>
                            <TableRow>
                                <TableCell>{t("Information society services and electronic commerce")}</TableCell>
                                <TableCell>{t("Infractions will prescribe: – Very serious, at 3 years. – Serious, at 2 years old. – Mild, at 6 months.")}</TableCell>
                                <TableCell>{t("From the recognition of the infraction")}</TableCell>
                                <TableCell>{t("3 years.")}</TableCell>
                                <TableCell>{t("Article 45 of Law 34/2002, of July 11, on services of the information society and electronic commerce.")}</TableCell>
                                {/* <TableCell>{t("Transitional regime:")}</TableCell> */}
                            </TableRow>
                            <TableRow>
                                <TableCell>{t("Insurance")}</TableCell>
                                <TableCell>{t("Data that would have been provided to them prior to the conclusion of a contract if it was not concluded, unless they had the specific consent of the interested party, which must be express if it were data related to health.")}</TableCell>
                                <TableCell>{t("From receipt of data")}</TableCell>
                                <TableCell>{t("10 days")}</TableCell>
                                <TableCell>{t("Article 99.9 of Law 20/2015, of July 14, on the organization, supervision and solvency of insurance and reinsurance entities.")}</TableCell>
                                {/* <TableCell>{t("Transitional regime:")}</TableCell> */}
                            </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>{t("Categories of recipients of personal data")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            <p>{t("Your personal data will not be communicated to third parties except for those service providers necessary for the development of the services of this website.")}</p>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>{t("Excercise of the rights of the interested party")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            <p>{t("Your personal data will not be communicated to third parties except for those service providers necessary for the development of the services of this website.")}</p>
            <ul>
                <li>{t("Request access to personal data concerning the interested party.")}</li>
                <li>{t("Request rectification or deletion.")}</li>
                <li>{t("Request the limitation of their treatment.")}</li>
                <li>{t("Oppose the treatment.")}</li>
                <li>{t("Request the portability of the data.")}</li>
                <li>{t("Reject automated individual decisions")}</li>
                <li>{t("Where appropriate, revoke your granted consent.")}</li>
                </ul>
            <p>{t("To exercise any of the rights described, the interested party must send said request accompanied by a photocopy of the DNI, passport or other valid document that identifies the User, or, where appropriate, the person who represents him. To do this, you must send the request to the following email address: innorma.reg@gmail.com")}</p>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>{t("Claims and Excercise of rights")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            <p>{t("Interested parties can address their requirements to the Data Protection Authorities, if they believe there is a problem with the way we are handling their personal data, through the so-called protection of rights. Said communication channel may be made through the following link of the Spanish Agency for Data Protection (AEPD): https://sedeagpd.gob.es/sede-electronica-web/vistas/formReclamacionDerechos/reclamacionDerechos.jsf")}</p>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>{t("Communications and Commercial Promotions")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            <p>{t("In accordance with the Law on Services of the Information Society and Electronic Commerce, the website does not carry out SPAM practices, so it does not send commercial emails electronically. that have not been previously requested or authorized by the user. Consequently, in each of the forms on the web, the user has the possibility of giving their express consent to receive the newsletter, regardless of the commercial information specifically requested. In accordance with the provisions of Law 34/2002 on Services of the Information Society and electronic commerce, the website undertakes not to send communications of a commercial nature without properly identifying them.")}</p>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>{t("Minors")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            <p>{t("In general, the website will not process personal information, with reliable knowledge, of minors under fourteen (14) years of age. In the event that the website, carrying out any control activity, discovers the involuntary collection of information regarding children under 14 years of age, it will carry out all the necessary measures that, as a service provider and data controller, it is obliged to undertake. and thus be able to delete such information, as soon as possible, except in those cases that, due to applicable legislation, it is necessary to keep. In accordance with the provisions of the European Data Protection Regulation, those over 13 years of age are entitled to grant their consent, except in those cases in which the law requires the assistance of the holders of parental authority or guardianship for its provision. ")}</p>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>{t("Acceptance and Consent")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            <p>{t("The user declares to have been informed of the conditions on protection of personal data, accepting and consenting to the treatment thereof by www.innorma.eu in the manner and for the purposes indicated in this privacy policy")}</p>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>{t("Changes in the privacy policy")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            <p>{t("The website reserves the right to modify this policy to adapt it to new legislation or jurisprudence, as well as to industry practices. In such cases, the Provider will announce on this page the changes introduced with reasonable anticipation of their implementation.")}</p>
            </Typography>
            </AccordionDetails>
            </Accordion>
            </div>
            <div className='CookiesPolicy' style={{color:"#0a3a62"}}>
            <h1>{t("Cookies Policy")}</h1>
            <p>{t("Our own cookies are used on this website to analyze our services and show you advertising related to your preferences.")}</p>
            <p>{t("We inform you that we can use cookies and other technologies on your computer provided that you have given your consent in the terms indicated in the corresponding section of this policy, except in the cases in which cookies are necessary for browsing the site. Website.")}</p>
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>{t("What are cookies?")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                {t("Cookies are small text files, which are automatically inserted in those telematic devices used by the user when they visit the website. These files that are downloaded can store data that can be updated and retrieved by the entity responsible for its installation. These files allow us to know, for statistical purposes, to improve the service and adapt it to your preferences, what your behavior is when browsing the web.")}
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>{t("Own and third-party cookies")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                <p>{t("OWN COOKIES: ")}</p>
                <p>{t("Technical cookies: Those that allow the user to navigate through restricted areas and the use of different functions available to customers.")}</p>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>{t("Acceptance of the Cookies policy")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                <p>{t("With the login in www.innorma.eu The User receives information about the use and policy of cookies through a 'banner' located at the bottom of the web page. Given this information, the User can perform the following actions:")}</p>
                <p>{t("Accept. This notice will not be displayed again when accessing any page of the portal during this session. This will imply that the User gives his express and unequivocal consent to the use of cookies, in the terms and conditions provided in this Policy.")}</p>
                <p>{t("Ignore.")}</p>
                <p>{t("All this, without prejudice to the configuration, deactivation and elimination of cookies that the User may adopt, and which are mentioned in the following section.")}</p>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>{t("How to modify the cookie settings?")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                <p>{t("In any case, we inform you that, given that certain 'cookies' are not necessary for the use of the Website, you can revoke your consent to their use at any time by rejecting their use in the configuration panel of this policy, or blocking them through your browser settings (see more details below).")}</p>
                <p>{t("Practically most browsers allow warning of the presence of 'cookies' or reject them automatically. If you reject them, you will be able to continue using our Website, although the use of some of its services may be limited. However, it should be noted that if you use your browser settings to block all storage technologies (including essential 'cookies') you may not have access to all or part of our Website.")}</p>
                <p>{t("You can also delete 'cookies' at any time through the navigation settings of your browser.")}</p>
                <p>{t("For more information on how to manage 'cookies' or to revoke consent for their use, we refer you to the relevant link:")}</p>
                <ul>
                    <li><a href='https://support.google.com/chrome/answer/95647?hl=es'>Chrome Cookies</a></li>
                    <li><a href='https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d'>Microsoft Cookies</a></li>
                    <li><a href='https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias'>Firefox Cookies</a></li>
                    <li><a href='https://support.apple.com/es-es/HT201265'>Safari Cookies</a></li>
                </ul>
                <p>{t("It is recommended to visit the following links for more information about it:")}</p>
                <p>{t("Internet Explorer: Tools -> Internet Options -> Privacy -> Settings.")}</p>
                <p>{t("From the tools menu, select 'Internet Options'. Click on the privacy tab. You will see the privacy setting slider which has six positions that allow you to control the amount of cookies that will be installed: Block all cookies, High, Medium High, Medium (default level), Low, and Accept all cookies.")}</p>
                <p>{t("For more information, you can consult the support of Microsoft or the browser Help.")}</p>
                <p>{t("Firefox: Tools -> Options -> Privacy -> History -> Custom Settings.")}</p>
                <p>{t("In the tools menu, select 'options'. Select the privacy label in the options box. From the dropdown menu choose 'use custom settings for history'. This will display the cookie options and you can choose to turn them on or off by checking the appropriate box.")}</p>
                <p>{t("For more information, you can consult the support of Mozilla or the browser Help.")}</p>
                <p>{t("Chrome: Settings -> Show advanced options -> Privacy -> Content settings.")}</p>
                <p>{t("In the settings menu, select 'show advanced settings' at the bottom of the page. Next, select the 'content settings' key in the privacy section.")}</p>
                <p>{t("The section at the top of the page that appears gives you information about cookies and allows you to set the cookies you want. It also allows you to delete any cookies that you have stored at that time.")}</p>
                <p>{t("For more information, you can consult the support of Google or the browser Help.")}</p>
                <p>{t("Safari: Preferences -> Security.")}</p>
                <p>{t("In the settings menu, select the 'preferences' option. Open the privacy tab. Select the option you want from the 'block cookies' section. Please note that certain features and full functionality of this Site may not be available after cookies are disabled.")}</p>
                <p>{t("For more information, you can consult the support of Apple or the browser Help.")}</p>
                
                
                
                
            </Typography>
            </AccordionDetails>
            </Accordion>

            </div>
            <br></br>
            <br></br>
            <br></br>
            <footer>
                <div className='language'><button className='language' onClick={() => i18n.changeLanguage('es')} >{t("Spanish")}</button><button className='language' onClick={() => i18n.changeLanguage('en')} >{t("English")}</button></div>
                <div className='copy'><small> Copyright &copy; 2022, Innorma. {t("All Rights Reserved")}</small></div>
            </footer>
      </Container>
    </div>
  )
}
