import React, { useEffect } from 'react'
import { Col, Form, ListGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

export const SearcherProd = ({written,setWritten,selectedelements,ultrasetter,written2,setWritten2,selectedcompounds,ultrasetter2,transparencySetter,innormaFormulas,userFormulas,ultrasetterMine,ultrasetterComer}) => {
  // useEffect(()=>{console.log('here',selectedelements)},[selectedelements])
  const { t, i18n } = useTranslation("newformulas");



  
  return (
    <div><Form>
    <Row>
      
      <Col md={6}>
        <Form.Group className="mb-3" controlId="formBasicSearchcompound">
          <Form.Label>{t("Ifra Restricted + Transparency Elements")}</Form.Label>
          <Form.Control type="search" placeholder={t("Constituent Name or CAS")} value={written2} onChange={(e)=>{setWritten2(e.target.value)}} />
          <div style={{display: 'flex', justifyContent: 'center'}}>
          <ListGroup style={{textAlign:'center',width:'33%',position:'absolute',border:'2px solid rgba(0, 0, 0, 0.8)'}}>
            {selectedcompounds.map((element,index)=>{
              // return(<ListGroup.Item key={element.id} action variant='primary' onClick={(e)=>{ultrasetter2(e,element.id)}} >{element.ncs_name} {element.CAS}</ListGroup.Item>)
              return(Object.keys(element).length==6 ? <ListGroup.Item key={index} action variant='primary' onClick={(e)=>{ultrasetter2(e,element.id)}} >{element.ncs_name} {element.CAS} {element.other_CAS}</ListGroup.Item> :
              (Object.keys(element).length==3 ? <ListGroup.Item key={index} action variant='black' onClick={(e)=>{transparencySetter(e,element.cas)}} >{element.name} {element.cas}</ListGroup.Item>: <ListGroup.Item key={index} action variant='primary' onClick={(e)=>{ultrasetter(e,element.id)}} >{element.name_ifra} {(element.synonym!=null ? `[synonym: ${element.synonym}]`:'')} {element.cas_number} </ListGroup.Item>))
            
            })}
      
        </ListGroup></div>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group className="mb-3" controlId="formBasicSearch">
          <Form.Label>{t("Local + Commercial Elements")}</Form.Label>
          <Form.Control type="search" placeholder={t("Name or CAS")} value={written} onChange={(e)=>{setWritten(e.target.value)}} />
          <div style={{display: 'flex', justifyContent: 'center'}}>
          <ListGroup style={{textAlign:'center',width:'33%',position:'absolute',border:'2px solid rgba(0, 0, 0, 0.8)'}}>
            {selectedelements.map((element,index)=>{
              return(Object.keys(element).length==3 ? <ListGroup.Item key={index} action style={{background:'rgb(194, 255, 202)'}} onClick={(e)=>{ultrasetterComer(e,element)}} >{element.name} {element.cas}</ListGroup.Item> :<ListGroup.Item key={index} action style={{background:'rgb(250, 219, 198)'}} onClick={(e)=>{ultrasetterMine(e,element)}} >{element.name} </ListGroup.Item>)
            })}
      
        </ListGroup></div>
        </Form.Group>
        
      </Col>
     </Row>
  </Form></div>
  )
}
