import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {I18nextProvider} from "react-i18next";
import reportWebVitals from './reportWebVitals';
import './i18n';
import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import formulas_es from "./translations/es/formulas.json";
import newformulas_es from "./translations/es/newformulas.json"
import pdf_es from "./translations/es/pdf.json"
import tags_es from "./translations/es/tags.json"
import landing_es from "./translations/es/landing.json"
import terms_es from "./translations/es/terms.json"
import ReactGA from "react-ga4";



i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',
  resources: {
    es: {
      'formulas': formulas_es,
      'newformulas':newformulas_es,
      'pdf':pdf_es,
      'tags':tags_es,
      'landing':landing_es,
      'terms':terms_es
    }
  }
});

i18next.use(LanguageDetector).init();

ReactGA.initialize("G-4DD4SBLTT3")

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <Suspense fallback={(<div> Loading </div>)}> */}
    <I18nextProvider i18n={i18next}>
      <App />

    </I18nextProvider>
      
    {/* </Suspense> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
