import React from 'react'
import { Form, Table } from 'react-bootstrap'
import { AiFillDelete } from 'react-icons/ai'
import { useTranslation } from 'react-i18next';

export const SelectedTransProdTable = ({selectedTrans,removeElement3,setSelectedTrans}) => {
  const { t, i18n } = useTranslation("newformulas");
    const handlePercent=(e,index)=>{
        let obj=selectedTrans
        obj[index].percent=e.target.value
        setSelectedTrans([...obj])
    }
    const handleIntensity=(e,index)=>{
        let obj=selectedTrans
        obj[index].intensity=e.target.value
        setSelectedTrans([...obj])
    }
  return ( 
    <div><h4>Non Restricted:</h4>
        <Table striped bordered hover variant='light'>
    <thead>
      <tr style={{textAlign:'center'}}>
        <th>{t("NAME")}</th>
        <th>CAS</th>
        <th>POSSIBLE RESTRICTED ELEMENT</th>
        <th>PERCENTAGE</th>
        <th>PURITY</th>
        <th className='table-danger border border-danger'>DELETE</th>
      </tr>
    </thead>
    
    <tbody>
    {selectedTrans.map((element,index)=>{
        const lol=element.ifraElement.wordMatch.filter(function(e) { return e !== 'none' })
          return(
            <tr key={index} style={{textAlign:'center'}}>
              <th style={{margin:'0px'}}>{element.ifraElement.name}</th>
              <th style={{margin:'0px'}}>{element.ifraElement.cas}</th>
              <th>{lol.length==0 ? '':lol.map((l,index)=>{return(<><p>{l}</p>{lol[index+1]==null?<></>:<hr></hr>}</>)})}</th>
              <th>
          <Form.Control size='sg' className={'text-black'} value={element.percent} onChange={(e)=>{handlePercent(e,index)}} style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>
          </th>
        
              
          <th>
          <Form.Control size='sg' className={'text-black'} value={element.intensity} onChange={(e)=>{handleIntensity(e,index)}} style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>
          </th>
          <th className='table-danger border border-danger' onClick={()=>{removeElement3(index)}} style={{cursor:'pointer'}}><AiFillDelete size='1.3em'/></th>
            </tr>)
  
      })}
      
      
     
      
    </tbody></Table></div>
    
    
  )
}
