// import axios from 'axios';
// // import Cookies from "universal-cookie";

// // const cookies = new Cookies();

// const baseURL = 'http://127.0.0.1:8000/api/'

// const axiosInstance = axios.create({
//     baseURL: baseURL,
//     timeout: 5000,
//     headers: {
//         Authorization: localStorage.getItem('access_token') 
//         ? 'JWT ' + localStorage.getItem('access_token')
//         : null,
//         'Content-Type':'application/json',
//         accept:'application/json',
//     },
// });

// export default axiosInstance


import axios from 'axios';
import { cifrar,descifrar } from './encryption';


const baseURL = 'https://www.innorma.eu/api';


const axiosInstance = axios.create({
	baseURL: baseURL,
	timeout: 500000,
	headers: {
		Authorization: 'Bearer ' + descifrar( localStorage.getItem('access_token') ),
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;

		if (typeof error.response === 'undefined') {
			alert(
				'A server/network error occurred. ' +
					'Looks like CORS might be the problem. ' +
					'Sorry about this - we will get it fixed shortly.'
			);
			return Promise.reject(error);
		}

		if (
			error.response.status === 401 &&
			originalRequest.url === baseURL + 'token/refresh/'
		) {
			window.location.href = '';
			return Promise.reject(error);
		}

		if (
			error.response.data.code === 'token_not_valid' &&
			error.response.status === 401 &&
			error.response.statusText === 'Unauthorized'
		) {
			const refreshToken = descifrar(localStorage.getItem('refresh_token'));

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				// exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);
				//console.log(tokenParts.exp);

				if (tokenParts.exp > now) {
					return axiosInstance
						.post('/token/refresh/', {
							refresh: refreshToken,
						})
						.then((response) => {
							// console.log('access refresh', response.data.access)
							// console.log('refresh refresh', response.data.refresh)
							localStorage.setItem('access_token', cifrar(response.data.access));
							localStorage.setItem('refresh_token', cifrar(response.data.refresh));

							axiosInstance.defaults.headers['Authorization'] =
								'Bearer ' + response.data.access;
							originalRequest.headers['Authorization'] =
								'Bearer ' + response.data.access;

							return axiosInstance(originalRequest);
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					console.log('Refresh token is expired', tokenParts.exp, now);
					window.location.href = '';
				}
			} else {
				console.log('Refresh token not available.');
				window.location.href = '';
			}
		}

		// specific error handling done elsewhere
		return Promise.reject(error);
	}
);

export default axiosInstance;