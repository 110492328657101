import React from 'react';
import {NavBaro} from './NavBaro';

import { Outlet } from 'react-router-dom';



export const WithNav = () => {
  return (
    <div><NavBaro/>
    <Outlet/></div>
  )
}
