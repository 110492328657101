import React from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
export const RestForm = ({inputRandEl,randEl,handleChange}) => {
  const { t, i18n } = useTranslation("newformulas");
  return (
    <div><Form> 
    <Form.Group className="mb-3" controlId="formBasicSearch">
      <Form.Label>{t("Name")}</Form.Label>
      <Form.Control onKeyDown={inputRandEl}  value={randEl} onChange={handleChange} type="search" placeholder={t("Name")} />
    </Form.Group>
  
    
  </Form></div>
  )
}
