import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

const to_number=(stri)=>{
    if (typeof stri=='string'){
        return Number(stri.replace(",", "."))

    }
    else{
        return Number(stri)

    } 

    
}
function rounder(num){
    return Math.round(num*1000000)/1000000
}

function rep(s,from,to){
    var out = "";
    // Most checks&balances ommited, here's one example
    if(to.length != 1 || from.length != 1)
      return null;
  
    for(var i = 0;i < s.length; i++){
      if(s.charAt(i) === from){
        out += to;
      } else {
        out += s.charAt(i);
      }
    }
    return out;
  }
  function minForPR(cas){
    if (cas.includes('494-40-6')){
        return 0.01

    }
    else if (cas.includes('140-29-4')){
        return 0.01

    }
    else if (cas.includes('78-59-1')){
        return 0.0013
    }
    else if (cas.includes('531-59-9')){
        return  0.01
    }
    else if (cas.includes('94-59-7')){
        return 0.01
    }
  }




export const LogicTable = ({finalConst,search_min_cat,selectedUses,selectedComps,selectedConsti,restElements,manualComps,finalTrans,selectedTrans,fullListComps,tresh}) => {
    const { t, i18n } = useTranslation("newformulas");
    const dictToList=(dict)=>{ return(Object.keys(dict).map((key)=>{
        return {field:key,descript:dict[key]}
    }))}
    const [descriptBool,setDescriptBool]=useState([])
    useEffect(()=>{
        // console.log('final',finalConst)
        
        const act=finalConst.map(el=>{return({
            casio:el.ifraElement.cas_number,
            active:(descriptBool.find(x => x.casio === el.ifraElement.cas_number)==null ? null :descriptBool.find(x => x.casio === el.ifraElement.cas_number).active),
            super:el})})
        // console.log('activation')
        // console.log(selectedUses.length==0)
        setDescriptBool([...act])

    },[finalConst])

    const setoAct=(e,index)=>{
        const changeDes=descriptBool
        //const ind=descriptBool.findIndex(x => x.casio === l.ifraElement.cas_number)
        // console.log(index)
        // console.log(descriptBool[index])
        changeDes[index].active=(changeDes[index].active=='active'? null : 'active')
        setDescriptBool([...changeDes])

    }
    const trick=(descriptBool,index)=>{
        if (descriptBool[index]==null){
            return null
        }
        else{
            return (descriptBool[index].active==null)

        }
    }
    const minn=(numm,tresh)=>{
        const al=to_number(tresh)
        const val=(numm/al)*100
        return (val<100?val:100)
    }
    const colorRow=(l)=>{
        
        
        const bool=(((minn(search_min_cat(l.super.ifraElement).min)>l.super.fullPercent*100,tresh) || search_min_cat(l.super.ifraElement).min=='See Notebox')? 'warning': 'danger')
        if (l.super.ifraElement.ifra_type=='PROHIBITION'){
            return 'danger'
        }
        if (l.super.ifraElement.ifra_type=='PROHIBITION_RESTRICTION'){
            if (l.super.procedence.includes("Element")){
                return 'danger'

            }
            else{
                return (minn(minForPR(l.super.ifraElement.cas_number),tresh)>l.super.fullPercent*100 ? 'warning': 'danger')

            }
           
        }
        if (l.super.ifraElement.ifra_type=='PROHIBITION_RESTRICTION_SPECIFICATION'){
            return bool
        }
        if ( l.super.ifraElement.ifra_type=='PROHIBITION_SPECIFICATION'){
            
            return 'danger'
        }
        if (l.super.ifraElement.ifra_type=='SPECIFICATION'){
            return 'warning'
        }
        if (l.super.ifraElement.ifra_type=='RESTRICTION_SPECIFICATION'){
            if (l.super.ifraElement.cas_number.includes('90028-68-5')){
                const minimum=minn(search_min_cat(l.super.ifraElement).min,tresh)
                const findo=finalConst.find(x => x.ifraElement.cas_number.includes('90028-67-4'))
                if (findo==null){
                    return (minn(search_min_cat(l.super.ifraElement).min,tresh)>l.super.fullPercent*100 ? 'warning': 'danger')
                }
                else {
                    return (minn(search_min_cat(l.super.ifraElement).min,tresh)>l.super.fullPercent*100+findo.fullPercent*100 ? 'warning': 'danger')

                }
            }
            if (l.super.ifraElement.cas_number.includes('90028-67-4')){
                const minimum=minn(search_min_cat(l.super.ifraElement).min,tresh)
                const findo=finalConst.find(x => x.ifraElement.cas_number.includes('90028-68-5'))
                
                if (findo==null){
                    return (minn(search_min_cat(l.super.ifraElement).min,tresh)>l.super.fullPercent*100 ? 'warning': 'danger')
                }
                else {
                    return (minn(search_min_cat(l.super.ifraElement).min,tresh)>l.super.fullPercent*100+findo.fullPercent*100 ? 'warning': 'danger')

                }
            }
            return bool
        }
        if (l.super.ifraElement.ifra_type=='RESTRICTION_PROHIBITION'){
            return bool
        }
        if (l.super.ifraElement.ifra_type=='RESTRICTION'){
            if (l.super.ifraElement.cas_number=='111-12-6'){
                const minimum=minn(search_min_cat(l.super.ifraElement).min,tresh)
                const findo=finalConst.find(x => x.ifraElement.cas_number === '111-80-8')
                if (findo==null){
                    return (minn(search_min_cat(l.super.ifraElement).min,tresh)>l.super.fullPercent*100 ? '': 'danger')
                }
                else {
                    return (minn(search_min_cat(l.super.ifraElement).min,tresh)>l.super.fullPercent*100+findo.fullPercent*100 ? '': 'warning')

                }
            }
            return (minn(search_min_cat(l.super.ifraElement).min,tresh)>l.super.fullPercent*100 ? '': 'danger')
        }
        if (minn(search_min_cat(l.super.ifraElement).min,tresh)>l.super.fullPercent*100){
            return (l.super.ifraElement.ifra_type=='RESTRICTION' ? '': 'warning')
        }
        else{
            return 'danger'
        }
    }
    const noteBox=(l)=>{
        const lol=[]
        if(l.super.ifraElement.prohibit_notes.length!=0){
            lol.push(l.super.ifraElement.prohibit_notes)
        }
        if(l.super.ifraElement.restricted_notes.length!=0){
            lol.push(l.super.ifraElement.restricted_notes)
        }
        if(l.super.ifraElement.specified_notes.length!=0){
            lol.push(l.super.ifraElement.specified_notes)
        }

        
        return(<>
        {/* {(l.super.ifraElement.prohibit_notes.length==0?<></>:<><p>{l.super.ifraElement.prohibit_notes}</p><hr></hr></>)}
        {(l.super.ifraElement.restricted_notes.length==0?<></>:<><p>{l.super.ifraElement.restricted_notes}</p><hr></hr></>)}
        {(l.super.ifraElement.specified_notes.length==0?<></>:<><p>{l.super.ifraElement.specified_notes}</p><hr></hr></>)}
         */}
         {lol.length==0 ? '':lol.map((l,index)=>{return(<><p>{l}</p>{lol[index+1]==null?<></>:<hr></hr>}</>)})}
        
        </>)
        
    }
    // const categoryBox=(l)=>{
    const catBox=(l)=>{
        if (search_min_cat(l.super.ifraElement).category=='See Notebox'){
            return 'See Notebox'
        }
        else {
            return `${(selectedUses.find(x => x.category === search_min_cat(l.super.ifraElement).category).name)} (${search_min_cat(l.super.ifraElement).category})`
        }
        

    }

    const minNumber=(l)=>{
        if (l.super.ifraElement.ifra_type=='PROHIBITION_RESTRICTION' && l.super.procedence.includes("Element")==false){
            return minForPR(l.super.ifraElement.cas_number)

        }

        return (selectedUses.length==0 ? 'Use not selected' : search_min_cat(l.super.ifraElement).min)


    }


    const innerDil=(l)=>{
        let fullIfra=0
        //console.log('inner dil',l)
        for (var i of l.ifraElement.composition){
            fullIfra=fullIfra+to_number(i.concen_constituent)

        }
        const otherDil=100-fullIfra
        return (otherDil/100)*((to_number(l.percent)/100)*(to_number(l.intensity)/100)*100)
    }

    const [fullDiluent,setFullDiluent]=useState(0)
    const [seeDiluents,setSeeDiluents]=useState(false)
    const [seeOther,setSeeOther]=useState(false)
    const [seeTrans,setSeeTrans]=useState(false)
    const [otherManualComps,setOtherManualComps]=useState([])
    
    useEffect(()=>{
        let middleOther=[]
        for (var comp of manualComps){
            for (var constituyente of comp.composition){
                if (constituyente.type=='unknown'){
                    middleOther.push({
                        name:constituyente.component,
                        cas:(constituyente.cas=='none'?'':constituyente.cas),
                        fullPercento:(to_number(constituyente.percent)/100)*(comp.percent/100),
                        procedence:comp.name

                    })

                }
            }

        }
        setOtherManualComps([...middleOther])


    }
    ,[manualComps])
    useEffect(()=>{
        let dil=0
        for (var i of selectedConsti){
            dil=dil+(to_number(i.percent)/100)*(1-(to_number(i.intensity)/100))*100

        }
        for (var i of selectedComps){
            dil=dil+((to_number(i.percent)/100)*(1-(to_number(i.intensity)/100))*100+innerDil(i))

        }
        for (var i of restElements){
            dil=dil+(to_number(i.percent)/100)*(1-(to_number(i.intensity)/100))*100

        }
        for (var l of manualComps){
            let totalInner=0
            for (var i of l.composition){
                totalInner=totalInner+to_number(i.percent)
                
    
            }
            const toShow=((to_number(l.percent)/100)*(1-(totalInner/100))*100)
            let compSquared=0
            for (var i of l.composition){
                if (i.type=='complex'){
                    const toPass={ifraElement:fullListComps.find(x=>x.CAS==i.cas),percent:100,intensity:100}
                    //console.log('inner dil 2',innerDil(toPass))
                    compSquared=compSquared+innerDil(toPass)*(1-toShow/100)
    
                }   
    
            }
           
            dil=dil+toShow+compSquared
        }
        for (var i of selectedTrans){
            dil=dil+(to_number(i.percent)/100)*(1-(to_number(i.intensity)/100))*100

        }
        
        setFullDiluent(dil)
    }
    ,[selectedComps,selectedConsti,restElements,manualComps,selectedTrans])
    

    // }

    const [fullTrans,setFullTrans]=useState(0)
    const [fullConstSum,setFullConstSum]=useState(0)
    useEffect(()=>{
        let counter=0
        for (var i of finalTrans){
            counter=counter+i.fullPercent
        }
        setFullTrans(counter)

    }
    ,[finalTrans])
    useEffect(()=>{
        let counter=0
        for (var i of finalConst){
            counter=counter+i.fullPercent
        }
        setFullConstSum(counter)

    }
    ,[finalConst])

    const [fullRest,setFullRest]=useState(0)
    useEffect(()=>{
        let counter=0
        for (var i of restElements){
            counter=counter+((to_number(i.percent)/100)*(to_number(i.intensity)/100))
        }
        for (var i of otherManualComps){
            counter=counter+i.fullPercento
        }
        setFullRest(counter)

    }
    ,[restElements,otherManualComps])

  return (
    <div><Table striped bordered hover>
    <thead>
        <tr>
            <th scope="col">CAS</th>
            <th scope="col">{t("Name")}</th>
            <th scope="col">{t("Type of restriction")}</th>
            <th scope="col">{t("max % restriction")}</th>
            <th scope="col">{t("Category restriction")}</th>
            <th scope="col"> {t("Notes")} </th>
            <th scope="col">{t("Procedence")}</th>
            <th scope="col">total %</th>
        </tr>
    </thead>
    <tbody>
{descriptBool.map((l,index) => {
    
    return (
        
        <>
            <tr onClick={(e)=>{setoAct(e,index)}} style={{cursor:'pointer'}} className={`table-${colorRow(l)}`}>
                <td>{l.super.ifraElement.cas_number}</td>
                <td>{l.super.ifraElement.name_ifra}</td>
                <td>{rep(l.super.ifraElement.ifra_type,'_',' ')}</td>
                 
                <td>{(to_number(minNumber(l))? minn(to_number(minNumber(l)),tresh):minNumber(l))}</td>
                <td>{(selectedUses.length==0 ? 'Use not selected' : catBox(l))}</td>
                <td>{noteBox(l)}</td>
                <td>{l.super.procedence.join(' | ')}</td>
                <td>{rounder(l.super.fullPercent*100)}</td>
                
            </tr>
           {(trick(descriptBool,index)?<></>:dictToList(l.super.ifraElement).map((l2)=>{
                return(<> <tr className='table-info'><td className='text-light' colSpan={8}>{`${l2.field}: ${l2.descript}`}</td></tr></>)
            }))}
            </>
            
    )})}
    <tr className='table-light' onClick={(e)=>{setSeeTrans(!seeTrans)}} style={{cursor:'pointer'}}>
        <td colSpan={7}>
            {t("Transparency") }
        </td>
        <td>{rounder(fullTrans*100)}

        </td>
    </tr>
    {(seeTrans?finalTrans.map((l)=>{
        return(
            <tr className='table-light'>
                <td colSpan={1}>{l.ifraElement.cas}</td>
                <td colSpan={5}>{l.ifraElement.name}</td>
                <td colSpan={1}>{l.procedence.join(' | ')}</td>
                <td>{rounder(l.fullPercent*100)}</td>
            </tr>


        )
    }
    ):<></>
    )}
    <tr className='table-dark' onClick={(e)=>{setSeeOther(!seeOther)}} style={{cursor:'pointer'}}>
        <td colSpan={7}>
            {t("Unknown")}
        </td>
        <td>{rounder(fullRest*100)}

        </td>
    </tr>
    {(seeOther?restElements.map((l)=>{
        return(
            <tr className='table-dark'>
                <td>{l.cas}</td>
                <td colSpan={6}>{l.name}</td>
                <td>{rounder(((to_number(l.percent)/100)*(to_number(l.intensity)/100)*100))}</td>
            </tr>


        )
    }
    ):<></>
    )}
    {(seeOther?otherManualComps.map((l)=>{
        return(
            <tr className='table-dark'>
                <td colSpan={1}>{l.cas}</td>
                <td colSpan={5}>{l.name}</td>
                <td colSpan={1}>{l.procedence}</td>
                <td>{rounder(l.fullPercento*100)}</td>
            </tr>


        )
    }
    ):<></>
    )}
    <tr className='table-secondary' onClick={(e)=>{setSeeDiluents(!seeDiluents)}} style={{cursor:'pointer'}}>
        <td colSpan={7}>
            {t("Diluents")}
        </td>
        <td>{rounder(fullDiluent)}

        </td>
    </tr>
    {(seeDiluents ? selectedConsti.map((l)=>{
        return(
            <tr className='table-secondary'>
                <td>{l.ifraElement.cas_number}</td>
                <td colSpan={6}>{t("Diluents of ") + l.ifraElement.name_ifra}</td>
                <td>{rounder((to_number(l.percent)/100)*(1-(to_number(l.intensity)/100))*100)}</td>
            </tr>


        )
    }
    ):<></>)
    }
    {(seeDiluents ? selectedComps.map((l)=>{
        return(
            <tr className='table-secondary'>
                <td>{l.ifraElement.CAS}</td>
                <td colSpan={6}>{t("Diluents of ") + l.ifraElement.ncs_name}</td>
                <td>{rounder(((to_number(l.percent)/100)*(1-(to_number(l.intensity)/100))*100)+innerDil(l))}</td>
            </tr>


        )
    }
    ):<></>)
    }
    {(seeDiluents ? selectedTrans.map((l)=>{
        return(
            <tr className='table-secondary'>
                <td>{l.ifraElement.cas}</td>
                <td colSpan={6}>{t("Diluents of ") + l.ifraElement.name}</td>
                <td>{rounder((to_number(l.percent)/100)*(1-(to_number(l.intensity)/100))*100)}</td>
            </tr>


        )
    }
    ):<></>)
    }
    {(seeDiluents ? restElements.map((l)=>{
        return(
            <tr className='table-secondary'>
                <td colSpan={7}>{t("Diluents of ") + l.name}</td>
                <td>{rounder(((to_number(l.percent)/100)*(1-(to_number(l.intensity)/100))*100))}</td>
            </tr>


        )
    }
    ):<></>)
    }
    {(seeDiluents ? manualComps.map((l)=>{
        let totalInner=0
        for (var i of l.composition){
            totalInner=totalInner+to_number(i.percent)
            

        }
        const toShow=((to_number(l.percent)/100)*(1-(totalInner/100))*100)
        let compSquared=0
        for (var i of l.composition){
            if (i.type=='complex'){
                const toPass={ifraElement:fullListComps.find(x=>x.CAS==i.cas),percent:100,intensity:100}
                //console.log('inner dil 2',innerDil(toPass))
                compSquared=compSquared+innerDil(toPass)*(1-toShow/100)

            }
            
            

        }
        return(
            <tr className='table-secondary'>
                <td colSpan={7}>{t("Diluents of ") + l.name}</td>
                <td>{rounder(toShow+compSquared)}</td>
            </tr>


        )
    }
    ):<></>)
    }
    <tr>
        <td colSpan={7}>
            {t("Total")}
        </td>
        <td>{rounder(fullDiluent+fullRest*100+fullTrans*100+fullConstSum*100)}

        </td>
    </tr>

    
    </tbody>
</Table></div>
  )
}
