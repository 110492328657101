import React, { useEffect, useState,useRef } from 'react'
import { Form, Table } from 'react-bootstrap'
import { AiFillDelete, AiOutlineFileExcel } from 'react-icons/ai'
import { VscFilePdf } from 'react-icons/vsc'
import axiosInstance from '../../protectedRoute/axios'
import { PdfSubTable } from './PdfSubTable'
import { useTranslation } from 'react-i18next';

  


export const PdfTable = ({pdfComps,removeElement,setPdfComps,setComposition,fullList,transparency,synonyms,fullListComps}) => {
  const { t, i18n } = useTranslation("newformulas");
  const [isLoading, setLoading] = useState([]);
  useEffect(()=>{setLoading([...pdfComps.map(x=>{return false})])},[pdfComps])

  // useEffect(() => {

  //   while(isLoading == true){
  //     alert('Is Loading!')
  //   }

  // },[isLoading])
  

    
    const handlePercent=(e,index)=>{
        let obj=pdfComps
        obj[index].percent=e.target.value
        setPdfComps([...obj])
    }
    // useEffect(()=>{console.log('pdf comps',pdfComps)}
    // ,[pdfComps])
    const activate=(index)=>{
        let o=pdfComps
        pdfComps[index].active=!pdfComps[index].active
        setPdfComps([...o])
    }




  // ///////////////////////////////////////////////////const inputFileRef = useRef( null );

  const onFilechange = ( e,index ) => {
    let formData = new FormData()
    // console.log(index)
    let ooo=isLoading
    ooo[index]=true
    setLoading([...ooo])
    formData.append('file', e.target.files[0])
    axiosInstance.post('excel/upload_data/', formData).then(
      (response) => {
        // console.log('aca esta la data del excel wacho ! ',response.data)
        let aaa=isLoading
        aaa[index]=false
        setLoading([...aaa])
        // hago lo de pdf comps
        var datos = Object.keys(response.data)
          .map(function(key) {
              return response.data[key];
          });
        
        let newComp=pdfComps
        const toCatt=datos.map(x=>{return ({
          cas:x.cas.replaceAll('\r\n', '\n'),
          component:x.component,
          percent:x.percent.toString(),
          type:x.type,
          possibleMatch:x.possibleMatch})})
       
        newComp[index].active=true

        newComp[index].composition=newComp[index].composition.concat(toCatt)
        // console.log(newComp)
        setPdfComps([...newComp])


      }
    ).catch(err => {
      // console.log('alert que diga como poner el excel correctamente!')
      let bbb=isLoading
      bbb[index]=false
      setLoading([...bbb])
      alert('The xlsx file must be prepared correctly, make sure there is only one page without excess rows or columns; also the main column must be named `components` and the other `percentage` (and `cas` if provided)')
      // console.log(err);
      return null;})
    /*Selected files data can be collected here.*/
    
    // axiosInstance.post('pdffiles/upload_data/',{"file": e.target.files[0]})
    // console.log('aca esta el excel bro', e.target.files[0] );
  }
  /////////////////////////////////////////////////////////////////////
  // const onBtnClick = () => {
  //   /*Collecting node-element and performing click*/
  //   inputFileRef.current.click();
  // }
  return (
    <>

<Table striped bordered hover >
    <thead>
        <tr className='table-info' style={{textAlign:'center'}}>
          <th colSpan={2}>{t("NAME")}</th>
          <th colSpan={1}>{t("PERCENTAGE")}</th>
          <th colSpan={2}>{t("Read Excel")}</th>
          <th className='table-danger border border-danger' colSpan={1}>{t("DELETE")}</th>
        </tr>
      </thead>
    
    
      
      <tbody>{pdfComps.map((element,index)=>{
            return(<>

              <tr className='table-info' key={index} style={{textAlign:'center'}} >
                <th colSpan={2} onClick={()=>{activate(index)}} style={{cursor:'pointer'}}>{element.name}</th>
                
                <th colSpan={1}>
            <Form.Control size='sg' className='text-white' value={element.percent} onChange={(e)=>{handlePercent(e,index)}}  style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>
            </th>
          
            
            <th colSpan={2} style={{cursor:'pointer'}}>
            
     
      
            
              {isLoading[index]? 'loading...' :<input
        type="file"
        
        onChange={(e)=>{onFilechange(e,index)}}
       
      />}
            </th>
            <th colSpan={1} className='table-danger border border-danger' onClick={()=>{removeElement(index)}} style={{cursor:'pointer'}}><AiFillDelete size='1.3em'/></th> 
              </tr>
              {((element.active)?<PdfSubTable composition={element.composition} index={index} {...{pdfComps,setPdfComps,fullList,transparency,synonyms,fullListComps}}/>:<></>)}</>)

    
        })}
        
        
        </tbody></Table>

    
    </>
  )
}
