import { t } from 'i18next';
import React, { useState, useEffect } from 'react'
import { Card, Button,Container,Row,Col,ButtonGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';


export const UseofProd = (props) => {
  
  const { t, i18n } = useTranslation("newformulas");
  const info= {"Lips":'category_1',"Axillae":"category_2","On face using fingertips":"category_3","Fine Fragance":"category_4","Body lotion using hands":"category_5a",
	"Face moisturizer":"category_5b","Hand cream":"category_5c","Baby Creams":"category_5d","Oral and lip exposure":"category_6","Rinse-off Hair":"category_7a",
	"Leave-on Hair":"category_7b","Anogenital":"category_8","Body and hand, rinse off":"category_9","Household care excluding aerosol":"category_10a",
	"Household care aerosol/spray":"category_10b","Skin contact with minimal fragance transfer without UV exposure":"category_11a",
	"Skin contact with minimal fragance transfer with UV exposure":"category_11b","Not intended/minimal  skin contact":"category_12"}
  var uses = [];
  let i=0
  for (const key in info){
    uses.push({category:info[key],name:key,active:false,place:i,variant:'secondary'})
    i=i+1
  }
    
  
  const [utiliz,setUtiliz]=useState((window.localStorage.getItem('utilStorage') ? JSON.parse(window.localStorage.getItem('utilStorage')) : uses))
  
  
  
  const setButton=(place)=>{
    let newutil=utiliz
    newutil[place].variant=(newutil[place].variant=='secondary') ? 'primary': 'secondary'
    newutil[place].active=(newutil[place].active==false) ? true : false
    setUtiliz([...newutil])
    // props.setSelectedUses(utiliz.filter(s=>(s.active==true)))
  }
  useEffect(()=>{props.setSelectedUses(utiliz.filter(s=>(s.active==true)))},[utiliz])


  
  
  
  // useEffect(()=>{
  //   console.log('here0')
  //   console.log(JSON.parse(window.localStorage.getItem('utilStorage')))
  //   setUtiliz([...JSON.parse(window.localStorage.getItem('utilStorage'))])
  // }
  // ,[])
  useEffect(()=>{
    window.localStorage.setItem('utilStorage',JSON.stringify(utiliz))
  },[utiliz])


  
  return (
    <div>
        <Container style={{width: "70%"}}>
        
            <h3 style={{textAlign:'center'}}>{t("Select the use of the product")}</h3>
            <hr></hr>

        {utiliz.map(element =>{

        return(<Button style={{margin:'5px'}} key={element.place} variant={element.variant} className={`text-${(element.variant=='secondary' ? 'black': 'white')}`} active={element.active} onClick={()=>{setButton(element.place)}} >{t(element.name)}</Button>);
         })}
     
    
        
        </Container>
    </div>
  )
}
//Array.from({length: 25}, (x, i) => i)
/*<Button style={{margin:'5px'}} key={0} variant={useForm[0].variant} active={useForm[0].active} onClick={setButton}>{uses[0].name}</Button>
        <Button style={{margin:'5px'}} key={1} variant={useForm[1].variant} active={useForm[1].active} onClick={setButton}>{uses[1].name}</Button>
        */