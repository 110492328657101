import React, { useRef, useState,useEffect  } from 'react'
import './contact.css'
import img1 from './media/865465-1@1x.png'
import Form from 'react-bootstrap/Form';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import gsap from "gsap"
import { useIntersection } from "react-use"
import axiosInstance from '../protectedRoute/axios';

import Card from '@mui/material/Card'
import { CardContent, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import CardActions from '@mui/material/CardActions';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { Container } from 'react-bootstrap';
import Paper from '@mui/material/Paper';
import { motion, useAnimation, useInView, Variants } from "framer-motion";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#a3a9e15c',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '550px',
    height: '30px',
    marginTop: '-10px',
    alignSelf: 'center',
    position: 'relative',
    left: '0px',
    opacity: 0.7,
    // marginBottom: '5px'
  }));


export default function Contact() {
    const { t, i18n } = useTranslation("landing");
    const ref = useRef(null)
    const inView = useInView(ref, { once: true }) 
    const animation = useAnimation()
    
    useEffect(() => {
        inView?animation.start({ opacity: 1,marginTop: '50px', transition: { type: "tween", duration:2 }}):
        animation.start({opacity:0,marginTop:'300px'})
        // console.log(i18n.language)
        // console.log('succ',successmes)

        // console.log(ref)
        // console.log(inView)
    },[inView,i18n])
    
    const [name,setName] = useState('')
    const [email,setEmail] = useState('')
    const [acceptlaw,setacceptlaw] = useState(false)
    const [passCard,setPassCard]=useState(false)
    const [correctcontact,setcorrectcontact]=useState(false)
    const [successmes,setsuccessmes]=useState(false)

    const [responserror,setresponserror] = useState('')
    const handleClick = () => {
        ReactGA.event({
            category:'contact',
            action:'contact received',
            label:'Contacto',
        })

        if (acceptlaw == true) {
        
            axiosInstance.post('contact/',{"name":name,"email":email},{params: { "language": i18n.language }}).then(function (response) {setsuccessmes(true)}).catch(function (error) {
                //console.log('here',error.response.data.errors.email)
                setresponserror(error.response.data.errors.email)
                setcorrectcontact(true)
                setPassCard(false)
                setacceptlaw(false)
                
          })
     
        }
        else {
            setPassCard(true)
            setcorrectcontact(false)
            setacceptlaw(false)
    
        }
      };

    // const sectionRef = useRef(null)
    // const intersection = useIntersection(sectionRef, {
    //     root:null,
    //     rootMargin: "0px",
    //     threshold:0.2
    // })
    // const fadeIn = element => {
    //     gsap.to(element,1 , {
    //       duration: 1.5,
    //         opacity: 1,
    //         y: -60,
    //         ease: "power2.in",
    //         stagger: {
    //             amount:0.3
    //         }
    //     })
    // }
    // const fadeOut = element => {
    //     gsap.to(element,1 , {
    //       duration: 2,
    //         opacity: 0,
    //         y: -20,
    //         ease: "power2.out"
    //     })
    // }
    // intersection && intersection.intersectionRatio < 0.2 ? fadeOut(".overlap-group-11") : fadeIn(".overlap-group-11")
  return (
    <motion.div animate = {animation} ref={ref}  className='overlap-group-11'>
        <img className='x865465-1' src={img1}></img>
        <div className='overlap-group-9'>
            <div className='try-it-for-free' id='contact'>
                {t("Try it for free!")}
            </div>
            <p className='leave-a-request-and'>
                {t("Leave a request and get a free trial")}
            </p>
            
            { passCard? 
                        <Item>
                            <Typography variant='h1' className="cardo1" sx={{ fontSize: 15, color: '#0a3a62'}} gutterBottom>
                                {t("Remember the terms!")}
                            </Typography>
                        </Item>
                        :<></>}
            
            { correctcontact? 
                        (responserror == "contacte with this email address already exists.")?
                        <Item elevation={5}>
                            <Typography className="cardo1" variant='h1' sx={{ fontSize: 15, color: "#0a3a62"}} gutterBottom>
                                {t("Contact with this email has been registered, please await for an answer or call us.")}
                            </Typography>
                        </Item> : 
                        <Item elevation={5}>
                            <Typography className="cardo1" variant='h1' sx={{ fontSize: 15, color: '#0a3a62'}} gutterBottom>
                                {t("Not valid email")}
                            </Typography>
                        </Item>
                        :<></>}
            
            { successmes?
                        <Item elevation={5}>
                            <Typography className="cardo1" variant='h1' sx={{ fontSize: 15, color: '#0a3a62'}} gutterBottom>
                                {t("Message has been received, we will contact you soon!")}
                            </Typography>
                        </Item> 
                        : <></>}
            <div className='overlap-group3-1'>
                <FormGroup>
                    <TextField id="standard-basic"  label="Name" variant="standard" onChange={(event) => {setName(event.target.value)}}/>
                    <br></br>
                    <br></br>
                    <TextField id="standard-basic"  label="Email" variant="standard" onChange={(event) => {setEmail(event.target.value)}}/>
                    <br></br>
                    <FormControlLabel className="agree" control={<Checkbox  name="agree"/>} label={<a href='./terms'>{t("I agree to the term of privacy policy")}</a>} onChange={(event) => {acceptlaw ? setacceptlaw(false) : setacceptlaw(true)}}></FormControlLabel>
                </FormGroup>
                <div className='overlap-group-100'>
                        <Button className="apply-a-request" variant="contained" onClick={handleClick}>{t("Submit")}</Button>
                        <br></br>


                    </div>
                

            </div>
            
            
            

        </div>
    </motion.div>
  )
}
