import React, { useEffect, useState } from 'react'
import { Container,Form,Row,Col,Table,ListGroup,InputGroup,Button } from 'react-bootstrap'
import axios from 'axios'
import { SelectedElement } from './SelectedElement'
import {AiFillDelete} from 'react-icons/ai'
import { SelectedProdTable } from './selectedprod/SelectedProdTable'
import { SearcherProd } from './selectedprod/SearcherProd'
import { useCookies } from 'react-cookie'
import axiosInstance  from '../protectedRoute/axios'
import { SelectedTransProdTable } from './selectedprod/SelectedTransProdTable'
import { useTranslation } from 'react-i18next';




export const SelectProd = ({setSelectedConsti,setSelectedComps,setFullList,transparency,synonyms,setSelectedTrans,selectedTrans,setFullListComps,setSelectedMine,setSelectedComer}) => {
  const { t, i18n } = useTranslation("newformulas");

  // const [token,setToken]=useCookies(['access_token','refresh_token','username'])
  const [elements,setElements]=useState([])
  const [selectedelements,setSelectedelements]=useState([])
  

  const [compounds,setCompounds]=useState([])
  const [selectedcompounds,setSelectedcompounds]=useState([])

  const [innormaFormulas,setinnormaFormulas]=useState([])
  const [userFormulas,setuserFormulas]=useState([])
  

  // useEffect(() => {
  //   console.log('aca se guarda el state con los datos de formulas overview innorma',innormaFormulas)
  //   console.log('aca se guarda el state con los datos de formulas overview usuario',userFormulas)

  // },[innormaFormulas,userFormulas])
  

  //seteo el parent
  
  
  //fetcheo
  useEffect(()=> {
    async function fetchData(){
     
      
      const request = await axiosInstance.get('/element/')
      //console.log('wata',request.data)
      setElements(request.data)
      setFullList(request.data)
      // setUltraselect(request.data.map((l)=>{return({ifraElement:l,percent:'54',intensity:'100'})})) // check if it fetches
    

      const request2 = await axiosInstance.get('/compuestos/')

      // importo el nombre de las formulas nuestras - Innorma Account
      const request3 = await axiosInstance.get('formulas-overview/',{ params: { user: 4 } })
      // aca guardamos todas los titles + ids
      
      const innormaPreSet=request3.data.map(el=>{return(el.name.split('/'))})
      let prepre=[]
      for (var i of innormaPreSet){
        let inPrePre={}
        let casPre=[]
        let namePre=''
        
        
        for (var j of i){
          const firstChar=j.charAt(0)
          if ( firstChar <='9' && firstChar >='0'){
            casPre.push(j)

          }
          else{
            namePre=j

          }

        }
        inPrePre={name:namePre,cas:casPre.join('\n')}
        prepre.push(inPrePre)

      }

      // console.log('innormaPreSet',prepre)
      // innormaSet=request3.data.map(el=>{return({name:,cas:})})
      let finalPrePre=[]
      let coounter=0
      for (var i of prepre){
        finalPrePre.push({...i,id:request3.data[coounter].id})
        coounter=coounter+1


      }
      // console.log('id de mierda',finalPrePre)


      setinnormaFormulas([...finalPrePre])
      

      //importo metadatos -> fijarse que esto deberia ejecutarse al clickear en la formula, para no importar al dope, total el cas y el titulo va a esar en el priemer pull por autor.
      // let metaformulas = []
      // for (var i = 0; i < request3.data.length; i++) {
      // const requestmeta = await axiosInstance.get('metadatos/',{ params: { formulas_over:  request3.data[i].id} })
        // metaformulas.push(requestmeta.data)
        // console.log(requestmeta.data)
        
        // console.log(request3.data[i])
      // }
      // setinnormaFormulas(...metaformulas)

      //llama por id
      // console.log('que parametro le pasa?',request3.data[0])
      const requestmeta = await axiosInstance.get('metadatos/',{ params: { formulas_over:  request3.data[0].id} })
      // console.log('request meta example',requestmeta.data)

      /////////////////////////////////////////// MISMO PERO CON EL USUARIO POSTA //////////////////
      // importo el nombre de las formulas del usuario
      const request4 = await axiosInstance.get('/user/auth/user/')
      // formula names
      const request5  = await axiosInstance.get('formulas-overview/',{ params: { user: request4.data.id } })
      // console.log(request5.data)
      setuserFormulas([...request5.data])
      /////////////////////////////////

      let result = request2.data
      
      const groupByCategory = result.reduce((group, product) => {
        const { ncs_name } = product;
        group[ncs_name] = group[ncs_name] ?? [];
        group[ncs_name].push(product);
        return group;
      }, {});
      let finalConst=[]
      for (const item in groupByCategory) {
        finalConst.push({
          ncs_name:item,
          CAS:groupByCategory[item][0].CAS,
          composition:groupByCategory[item],
          ncs_botanic_name:groupByCategory[item][0].ncs_botanic_name,
          other_CAS:groupByCategory[item][0].other_CAS,
          id:item
        })
        
      }
      //console.log(groupByCategory)
      //console.log(request2.data)
      // console.log(finalConst)
      //setCompounds(request2.data)
      setCompounds(finalConst)
      setFullListComps(finalConst)
      // setUltraselectcompounds(finalConst.map((l)=>{return({ifraElement:l,percent:'45',intensity:'78'})})) //uwu
      
      
      
    }
    fetchData()
    
  }
  ,[])
  const [ultraselect,setUltraselect]=useState((window.localStorage.getItem('constiStorage') ? JSON.parse(window.localStorage.getItem('constiStorage')) : []))
  const [ultraselectcompounds,setUltraselectcompounds]=useState((window.localStorage.getItem('complexStorage') ? JSON.parse(window.localStorage.getItem('complexStorage')) : []))
  
  const [mineElements,setMineElements]=useState((window.localStorage.getItem('mineStorage') ? JSON.parse(window.localStorage.getItem('mineStorage')) : []))
  const [comerElements,setComerElements]=useState((window.localStorage.getItem('comerStorage') ? JSON.parse(window.localStorage.getItem('comerStorage')) : []))
  
  useEffect(()=>{
    
    setSelectedConsti(ultraselect)
    setSelectedComps(ultraselectcompounds)
    setSelectedMine(mineElements)
    setSelectedComer(comerElements)},[ultraselect,ultraselectcompounds,mineElements,comerElements])

  //first
  const [written,setWritten] = useState('')
  // innormaFormulas,userFormulas

  useEffect(()=> {
    // console.log(synonyms)
    const val=written
    if (val==''){setSelectedelements([])}
    else {
    
    const matches0 = innormaFormulas.filter(s => (s.name.toLowerCase().includes(val.toLowerCase()) || s.cas.includes(val)));
    // const preMatches1 = synonyms.filter(s => (s.synonym.toLowerCase().includes(val.toLowerCase())))
    // const matches1=userFormulas.map(l=>{return({...elements.find(s => (s.cas_number.includes(l.cas))),synonym:l.synonym})})
    const matches2 = userFormulas.filter(s => (s.name.toLowerCase().includes(val.toLowerCase())));
    const matches = matches2.concat(matches0)
    
    setSelectedelements([...matches.slice(0,14)])}}
    
    
    
    ,[written])
  const ultrasetterMine=async (e,element)=>{
    e.preventDefault();
    setWritten('')
    setWritten2('')
    

    const requestmeta = await axiosInstance.get('metadatos/',{ params: { formulas_over:  element.id} })
    
    
    setMineElements(mineElements.concat({ifraElement:requestmeta.data[0],percent:'',intensity:'100'}))
    

  }
  const ultrasetterComer=async (e,element)=>{
    e.preventDefault();
    setWritten('')
    setWritten2('')
    const requestmeta = await axiosInstance.get('metadatos/',{ params: { formulas_over:  element.id} })
    setComerElements(comerElements.concat({ifraElement:requestmeta.data[0],percent:'',intensity:'100',name:element.name,cas:element.cas}))
    
  }

  const ultrasetter=(e,id)=>{
    e.preventDefault();
    setWritten('')
    setWritten2('')
    const val=id
    const matches = elements.find(x => x.id === val)

    setUltraselect(ultraselect.concat({ifraElement:matches,percent:'',intensity:'100'}))
    
  }

  // second
  const [written2,setWritten2] = useState('')

  useEffect(()=> {
    const val=written2
    if (val==''){setSelectedcompounds([])}
    else {
    
      // const matches = compounds.filter(s => (s.ncs_name.toLowerCase().includes(val.toLowerCase()) || s.CAS.includes(val) || s.ncs_botanic_name.toLowerCase().includes(val.toLowerCase()))).slice(0,8);
      const matches0 = elements.filter(s => (s.name_ifra.toLowerCase().includes(val.toLowerCase()) || s.cas_number.includes(val)));
      const preMatches1 = synonyms.filter(s => (s.synonym.toLowerCase().includes(val.toLowerCase())))
      const matches1=preMatches1.map(l=>{return({...elements.find(s => (s.cas_number.includes(l.cas))),synonym:l.synonym})})
      const matches2 = compounds.filter(s => (s.ncs_name.toLowerCase().includes(val.toLowerCase()) || s.CAS.includes(val) || s.other_CAS.includes(val) || s.ncs_botanic_name.toLowerCase().includes(val.toLowerCase())));
      const matches3 = transparency.filter(s => (s.name.toLowerCase().includes(val.toLowerCase()) || s.cas.includes(val)));
      // console.log('matches0',matches0)
      // console.log('matches1',matches1)
      // console.log('matches2',matches2)
      // console.log('matches3',matches3)
      const matches = matches0.concat(matches1,matches2,matches3)
     
      setSelectedcompounds([...matches.slice(0,14)])}},[written2])

  const ultrasetter2=(e,id)=>{
    e.preventDefault();
    setWritten2('')
    setWritten('')
    const val=id
    const matches = compounds.find(x => x.id === val)
    setUltraselectcompounds(ultraselectcompounds.concat({ifraElement:matches,percent:'',intensity:'100'}))
    
  }
  const transparencySetter=(e,cas)=>{
    e.preventDefault();
    setWritten2('')
    setWritten('')
    const val=cas
    const matches = transparency.find(x => x.cas === val)
    setSelectedTrans(selectedTrans.concat({ifraElement:matches,percent:'',intensity:'100'}))
    
  }


  /////
  const removeElement=(index)=>{
    let o=ultraselect
    const removed=o.splice(index,1)
    setUltraselect([...o])
  }
  const removeElement2=(index)=>{
    let o=ultraselectcompounds
    const removed=o.splice(index,1)
    setUltraselectcompounds([...o])
  }

  const removeElement3=(index)=>{
    let o=selectedTrans
    const removed=o.splice(index,1)
    setSelectedTrans([...o])
  }
  const removeElement4=(index)=>{
    let o=mineElements
    const removed=o.splice(index,1)
    setMineElements([...o])
  }
  const removeElement5=(index)=>{
    let o=comerElements
    const removed=o.splice(index,1)
    setComerElements([...o])
  }
  
  
  useEffect(()=>{
    window.localStorage.setItem('constiStorage',JSON.stringify(ultraselect))
  },[ultraselect])
  useEffect(()=>{
    window.localStorage.setItem('complexStorage',JSON.stringify(ultraselectcompounds))
  },[ultraselectcompounds])
  useEffect(()=>{
    window.localStorage.setItem('mineStorage',JSON.stringify(mineElements))
  },[mineElements])
  useEffect(()=>{
    window.localStorage.setItem('comerStorage',JSON.stringify(comerElements))
  },[comerElements])

  
  
  return (
    <div><Container style={{width: "70%"}}>
        
    <h3 style={{textAlign:'center'}}>{t("Select the product")}</h3>
    <hr></hr>
    <SearcherProd {...{written,setWritten,selectedelements,ultrasetter,written2,setWritten2,selectedcompounds,ultrasetter2,transparencySetter,innormaFormulas,userFormulas,ultrasetterMine,ultrasetterComer}}/>



    {(ultraselect.length+ultraselectcompounds.length+mineElements.length+comerElements.length==0) ? <></> : <SelectedProdTable {...{ultraselect,setUltraselect,ultraselectcompounds,setUltraselectcompounds,removeElement,removeElement2,mineElements,comerElements,removeElement4,removeElement5,setMineElements,setComerElements}}/>}
    {(selectedTrans.length==0) ? <></> : <SelectedTransProdTable {...{selectedTrans,removeElement3,setSelectedTrans}}/>} 
    
    
</Container>
    </div>
  )
}
