import React, { useRef, useState,useEffect  } from 'react'
import './why.css'
import img1 from './media/untitled-design--3---1@1x.png'
import gsap from "gsap"
import { useIntersection } from "react-use"
import { motion, useAnimation, useInView, Variants } from "framer-motion";
import { useTranslation } from 'react-i18next';

function Why() {
  const { t, i18n } = useTranslation("landing");
  const ref = useRef(null)
  const [dentro, setdentro] = useState(false)
  const inView = useInView(ref, { once: true }) 
  const animation = useAnimation()
  
  useEffect(() => {
      inView?animation.start({ opacity: 1,marginTop:'0px', transition: { type: "tween", duration:2 }}):
      animation.start({opacity:0,marginTop:'120px'})

      // console.log(ref)
      // console.log(inView)
  },[inView])
  
  // const sectionRef = useRef(null)
  // const intersection = useIntersection(sectionRef, {
  //     root:null,
  //     rootMargin: "0px",
  //     threshold:0.3
  // })
  // const fadeIn = element => {
  //     gsap.to(element,1 , {
  //         opacity: 1,
  //         y: -60,
  //         ease: "power2.in",
  //         stagger: {
  //             amount:0.3
  //         }
  //     })
  // }
  // const fadeOut = element => {
  //     gsap.to(element,1 , {
  //         opacity: 0,
  //         y: -20,
  //         ease: "power2.out"
  //     })
  // }
  // intersection && intersection.intersectionRatio < 0.3 ? fadeOut(".about") : fadeIn(".about")
  return (
    <motion.div animate = {animation} ref={ref}  id='about' className='about'>
        <div className='why-innorma outfit-bold-astronaut-blue-60px'>{t("Why INNORMA?")}</div>
        <div className='flex-row'>
          <img className='untitled-design-3-1' src={img1}/>
          <div className='group-2'>
            <div className='ellipse-5'></div>
            <div className='ellipse-6'></div>
            <div className='ellipse-7'></div>
            <div className='ellipse-8'></div>
          </div>
          <div className='flex-col roboto-normal-black-20px'>
            <div className='we-make-process-of-c'>
              {t("We facilitate the creation process. Whether you have a chromatogram ready or you just finished your product and need to add each component.")}
            </div>
            <div className='we-manage-every-elem'>
                {t("We manage all the elements under IFRA (transparency, restricted, prohibited, etc.); allowing you to search for every possible name or their CAS. ")}
                {t("We know how time-consuming it can be to do NCS product calculations with duplicate elements, but this is no longer an issue.")}
            </div>
            <div className='upload-your-chromato'>
              {t("Upload your chromatogram and we will automatically find the right element and make suggestion according to regulations, ")}
              {t("contemplating each category selected and elements of the composition. Even if you dont know the CAS, the program will find it and add it for you.")}
            </div>
            <div className='you-will-have-your-c'>
              {t("You will have your certificate in Spanish or English in minutes. We're here to make your job easier, so we're always there for you.")}
            </div>
          </div>
        </div>
    </motion.div>
  )
}

export default Why