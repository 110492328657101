import React, { useEffect, useState, useParams, useLocation, useRef } from 'react'
import {Table,Button,Container,Form,FormControl,Col,Row} from 'react-bootstrap'
import axiosInstance from '../protectedRoute/axios'
import { FormulaTable } from './FormulaTable'
import { useReactToPrint } from 'react-to-print';
import { PdfToPrintFormulas } from '../newformula/pdf/PdfPrintFormulas';
import { useTranslation } from 'react-i18next';
import { Logic } from '../newformula/logic/Logic';
import { useNavigate } from 'react-router-dom';
import useStore from './stores/main';
import { cifrar } from '../protectedRoute/encryption';
import ReactGA from "react-ga";




export const SearchFormula = ({setFormulaSeleccionada,formulaSeleccionada,elements,fullList,fullListComps}) => {
  const state = useStore()
  const { t, i18n } = useTranslation('formulas');
  const navigate = useNavigate();
  

  const [transparency,setTransparency]=useState(require('../../f_trans.json'))

  const [selectedUses,setSelectedUses]=useState([])
  const [selectedComps,setSelectedComps]=useState([])
  const [selectedConsti,setSelectedConstis]=useState([])
  const [restElements,setRestElements]=useState([])
  const [manualComps,setManualComps]=useState([])
  const [selectedTrans,setSelectedTrans]=useState([])
  const [finalConst,setFinalConst]=useState([])
  const [finalTrans,setFinalTrans]=useState([])
  const [tresh,setTresh]=useState('100')
  const [selectedMine,setSelectedMine]=useState([])
  const [selectedComer,setSelectedComer]=useState([])

  const [postData, updateFormData] = useState('')
  
  
  // useEffect(()=>{console.log(formulaSeleccionada)},[formulaSeleccionada])
  
  useEffect(()=>{
    
    setSelectedUses(formulaSeleccionada.supermeta.selectedUses)
    setSelectedComps(formulaSeleccionada.supermeta.selectedComps)
    setSelectedConstis(formulaSeleccionada.supermeta.selectedConsti)
    setRestElements(formulaSeleccionada.supermeta.restElements)
    setManualComps(formulaSeleccionada.supermeta.manualComps)
    setSelectedTrans(formulaSeleccionada.supermeta.selectedTrans)
    setTresh((formulaSeleccionada.supermeta.tresh?formulaSeleccionada.supermeta.tresh:'100'))
    setSelectedMine(formulaSeleccionada.supermeta.selectedMine)
    setSelectedComer(formulaSeleccionada.supermeta.selectedComer)

    

  },[formulaSeleccionada])


  const info= {"Lips":'category_1',"Axillae":"category_2","On face using fingertips":"category_3","Fine Fragance":"category_4","Body lotion using hands":"category_5a",
	"Face moisturizer":"category_5b","Hand cream":"category_5c","Baby Creams":"category_5d","Oral and lip exposure":"category_6","Rinse-off Hair":"category_7a",
	"Leave-on Hair":"category_7b","Anogenital":"category_8","Body and hand, rinse off":"category_9","Household care excluding aerosol":"category_10a",
	"Household care aerosol/spray":"category_10b","Skin contact with minimal fragance transfer without UV exposure":"category_11a",
	"Skin contact with minimal fragance transfer with UV exposure":"category_11b","Not intended/minimal  skin contact":"category_12"}
  var uses = [];
  let i=0
  for (const key in info){
    uses.push({category:info[key],name:key,active:false,place:i,variant:'secondary'})
    i=i+1
  }
  
  
  const handleUpdate = () => {
    
    // seteo los local storages y voy a new formula
    window.localStorage.setItem('updateBoolStorage',JSON.stringify(true))
    window.localStorage.setItem('titleStorage',JSON.stringify(formulaSeleccionada.meta.name))
    window.localStorage.setItem('restStorage',JSON.stringify(restElements))
    window.localStorage.setItem('transStorage',JSON.stringify(selectedTrans))
    window.localStorage.setItem('manualCompsStorage',JSON.stringify(manualComps))
    window.localStorage.setItem('complexStorage',JSON.stringify(selectedComps))
    window.localStorage.setItem('constiStorage',JSON.stringify(selectedConsti))
    window.localStorage.setItem('idStorage',JSON.stringify(cifrar(JSON.stringify(formulaSeleccionada.meta.id))))
    window.localStorage.setItem('tresh',JSON.stringify(tresh))
    window.localStorage.setItem('mineStorage',JSON.stringify(selectedMine))
    window.localStorage.setItem('comerStorage',JSON.stringify(selectedComer))

    state.meta.push(formulaSeleccionada)
    

    
    
    
    let uses2=uses
    let counter=0
    for (var used of uses2){
      const findo=selectedUses.find(x=>x.category == used.category)
      if (findo){
        uses2[counter].active=true
        uses2[counter].variant='primary'

      }
      counter=counter+1
      


    }
    window.localStorage.setItem('utilStorage',JSON.stringify(uses2))

    navigate('/newformula/')
    
    
    // async function fetchData(){
    //   console.log(formulaSeleccionada.meta.id)
    //   const request  = await axiosInstance.get('metadatos/',{ params: { formulas_over: formulaSeleccionada.meta.id } })
    //   console.log('esta es la metaaaa', request.data[0])


    // }
    // fetchData()
  }
  const handleDelete = () => {
    var result = window.confirm('are you sure?')  
    if (result==true){
      
    async function fetchData(){

      const request  = await axiosInstance.delete('formulas-overview/',{ params: { user: formulaSeleccionada.meta.author, pk: formulaSeleccionada.meta.id } })
      window.location.reload(false)
      
    }
    fetchData()

    }
    

  
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // onBeforePrint: () => document.title = title,
    // onAfterPrint: () => document.title = title
  });


  return (
    <div>
      <br/>

        <Container> 
          <h1 style={{textAlign:'left'}}>{formulaSeleccionada.meta.name}</h1>
          <Logic {...{selectedUses,selectedComps,selectedConsti,restElements,manualComps,fullList,finalConst,setFinalConst,updateFormData,selectedTrans,finalTrans,setFinalTrans,transparency,fullListComps,tresh,selectedMine,selectedComer}} ></Logic> 
     
        {/* <Table striped bordered hover>
  <thead>
    <tr>
      <th>CAS</th>
      <th>{t('Name')}</th>
      <th>{t('Type of Restriction')}</th>
      <th>{t('Max % Restriction')}</th>
      <th>{t('Category Restriction')}</th>
      <th>{t('Notes')}</th>
      <th>{t('Procedence')}</th>
      <th>Total %</th>
    </tr>
  </thead>
  <tbody>
    {formulaSeleccionada.array.map((l)=>{
      return(
        <tr>
          <td>{l.cas}</td>
          <td>{l.name}</td>
          <td>{l.type_of_restriction}</td>
          <td>{l.max_Restr}</td>
          <td>{t(l.cat_restr)}</td>
          <td>{l.notes}</td>
          <td>{l.procedence}</td>
          <td>{l.total_in_finished}</td>
          
        </tr>
      )
    })}
    
  </tbody>
</Table> */}

<Button onClick={handleUpdate} variant="outline-success">{t("Update")}</Button>{' '}

{(formulaSeleccionada.array.length==0?<></>:<PdfToPrintFormulas {...{formulaSeleccionada,elements}} ref={componentRef}></PdfToPrintFormulas>)}

<Button  onClick={(e)=>{handlePrint(e)}} variant="outline-info">{t("Print Certificate")}</Button>{' '}
<Button onClick={handleDelete} variant="outline-danger">{t("Delete")}</Button>{' '}
</Container><br/><hr/><hr/><br/>
{/* <Row>
{searched.map(element =>{
        return(<Col md={6}> {element} </Col>);
    })}</Row> */}


    </div>
  )
}
