import CryptoJS from 'crypto-js'


export const cifrar = (texto) => {
// const utf8 = CryptoJS.enc.Base64.parse(texto);
// const utf8 = CryptoJS.enc.Base64.parse(texto);
// console.log('utf version',utf8)

var textoCifrado = CryptoJS.AES.encrypt(texto,'el!mejor!equipo!de!los!ultimos!50!años!').toString()

return textoCifrado;
}
// export const descifrar = (texto) => {
//     //seems that the problem is that excecutes on the beginning and is null so should execute later!
//     try{
//         console.log('here we are 1',texto)
//         const utf8 = CryptoJS.enc.Base64.parse(texto);
//         // console.log('here we are 2',utf8)
//         var bytes = CryptoJS.AES.decrypt(utf8, 'el!mejor!equipo!de!los!ultimos!50!años!')
//         var textDescifrado=bytes.toString(CryptoJS.enc.Base64)
//         console.log('token',textDescifrado)
//         // const Base64 = CryptoJS.enc.Base64.parse(texto);
//         return textDescifrado}
//     catch{
//         return texto

//     }
     
// }
export const descifrar = (texto) => {
    //seems that the problem is that excecutes on the beginning and is null so should execute later!
    // console.log('here we are 1',texto)
    // console.log('here we are 2',utf8)
    
    if (texto == null){
        return texto
    }
    else{
        var bytes = CryptoJS.AES.decrypt(texto,'el!mejor!equipo!de!los!ultimos!50!años!')
        var textDescifrado=bytes.toString(CryptoJS.enc.Utf8)
        // console.log('token',textDescifrado)
        return textDescifrado
    }
    // const Base64 = CryptoJS.enc.Base64.parse(texto);
    }
    
    

     
