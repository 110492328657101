import React from 'react'
import { useCookies } from 'react-cookie'
import { Outlet,Navigate } from 'react-router-dom'
import { descifrar } from '../protectedRoute/encryption'


const useAuth=()=>{


}

// export const ProtectedRoutes = () => {
//     const [token]=useCookies(['access_token','refresh_token','username'])
//   return (token['access_token'] ? <Outlet/>: <Navigate to='/' />)
    
  
// }
export const ProtectedRoutes = () => {
  // var [token] = [localStorage.getItem('access_token'),localStorage.getItem('refresh_token'),localStorage.getItem('username')]
  return (descifrar(localStorage.getItem('access_token')) ? <Outlet/>: <Navigate to='/' />)
    
  
}
