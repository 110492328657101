import React, { useEffect, useState } from 'react'
import { Container, Form } from 'react-bootstrap'
import { PdfTable } from './pdfRead/PdfTable'

import { useTranslation } from 'react-i18next';
export const PdfRead = ({fullList,setManualComps,transparency,synonyms,fullListComps}) => {
  const { t, i18n } = useTranslation('newformulas');

    const testAxios=[
        {cas: 'none', component: 'a-pinene', ifra: 'none', percent: '1.31',selectedIfra:'no'},
        {cas: 'none', component: 'a-pinene', ifra: 'Anisyl alcohol', percent: '1.31',selectedIfra:'no'},
        {cas: 'none', component: 'a-pinene', ifra: 'none', percent: '1.31',selectedIfra:'no'},
        {cas: 'none', component: 'a-pinene', ifra: 'none', percent: '1.31',selectedIfra:'no'}]

    const [pdfComps,setPdfComps]=useState((window.localStorage.getItem('manualCompsStorage') ? JSON.parse(window.localStorage.getItem('manualCompsStorage')) : []))
    useEffect(()=>{
      setManualComps([...pdfComps])
    }
    ,[pdfComps])
    const [randEl,setRandEl]=useState('')
  const handleChange=(e)=>{
    setRandEl(e.target.value)
  }
  const inputRandEl=(e)=>{
    
    if (e.code == 'Enter' || e.code =='NumpadEnter') {
      e.preventDefault()
      setPdfComps(pdfComps.concat([{name:randEl,percent:'',composition:[],active:false}]))
      setRandEl('')
    }}
    const removeElement=(index)=>{
    
        let o=pdfComps
        const removed=o.splice(index,1)
        
        setPdfComps([...o])
      }
    const setComposition=(index)=>{
        let o=pdfComps
        o[index].composition=testAxios
        pdfComps[index].active=true
        setPdfComps([...o])

    }
    useEffect(()=>{
      window.localStorage.setItem('manualCompsStorage',JSON.stringify(pdfComps))
    },[pdfComps])
  return (
    <>
        <Container style={{width: "70%"}}>
        
        <h3 style={{textAlign:'center'}}>{t("Chromatography - Excel")}</h3>
        <hr></hr>
        <Container style={{width: "60%"}}>
        <Form> 
    <Form.Group className="mb-3" controlId="formBasicSearch">
      <Form.Label>{t("Name")}</Form.Label>
      <Form.Control  onKeyDown={inputRandEl}  value={randEl} onChange={handleChange} type="search" placeholder={t("Name")} />
    </Form.Group>
  
    
  </Form>

  
</Container>
        
{(pdfComps.length==0)? <></>: <PdfTable fullList={fullList} pdfComps={pdfComps} removeElement={removeElement} setPdfComps={setPdfComps} setComposition={setComposition} {...{transparency,synonyms,fullListComps}} />}

        
        </Container>


    </>
  )
}
