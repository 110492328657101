import logo from './logo.svg';
import './App.css';
//import './assets/css/mdb.dark.min.css'
//import 'bootstrap/dist/css/bootstrap.css'
//import './assets/css/bootstrap.min.css'
import './assets/scss/custom.scss'
import {Formulas} from './components/Formulas';
import {NewFormula} from './components/NewFormula';
import {Login} from './components/Login';
import {Landing} from './components/Landing';
import {NavBaro} from './components/nav/NavBaro';
import {BrowserRouter,Route,Routes} from 'react-router-dom';
import { WithoutNav } from './components/nav/WithoutNav';
import { WithNav } from './components/nav/WithNav';
// import {CookiesProvider} from 'react-cookie'
import { ProtectedRoutes } from './components/protectedRoute/ProtectedRoutes';

import axiosInstance from './components/protectedRoute/axios';
import Terms from './components/Terms';
import ReactGA from "react-ga4";
import React, { useEffect } from 'react';

// ReactGA.initialize("G-4DD4SBLTT3")
// ReactGA.initialize("G-4DD4SBLTT3")



function App() {
  // ReactGA.send({ hitType: "pageview", page: window.location.pathname})
//   useEffect(() => {
//     ReactGA.pageview(window.location.pathname)
//   },[])
  return (
    <div className="light">
      <BrowserRouter>
      <Routes>
        <Route element={<WithoutNav/>}>
          <Route exact path='/login' element={<Login/>}/>
          <Route exact path='' element={<Landing/>}/>
          <Route exact path='/terms' element={<Terms/>}/>
          </Route>
        <Route element={<ProtectedRoutes/>}>
        <Route element={<WithNav/>}>
      <Route exact path='/formulas' element={<Formulas/>}/>
      <Route exact path='/newformula' element={<NewFormula/>}/>
      </Route>
      </Route> 
      </Routes>
      </BrowserRouter>
    </div>
  );
}


export default App;
