import React, { useEffect, useState } from 'react'
// import {Table,Button,Container,Card, Row, Col, CardGroup,CardDeck} from 'react-bootstrap'

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { Container } from 'react-bootstrap';
import Paper from '@mui/material/Paper';
import axiosInstance from '../protectedRoute/axios';
import { useTranslation } from 'react-i18next';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#f5f5f5',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
// useEffect((e) => {
  
// })
// const [tabletoshow,setTabletoshow] = useState([])



export const CardsFormulas = ({form_views,setFormView,formulaSeleccionada,setFormulaSeleccionada}) => {


      const handleFormulaView = (e) => {
        
        // need to use fomula_over id to do this.
        async function fetchData(e){
             const request  = await axiosInstance.get('formulas-body/',{ params: { id: e.id } })
            //  console.log('que es esto',request.data)

             
            const request2  = await axiosInstance.get('metadatos/',{ params: { formulas_over: e.id } })
            // console.log('supermeta', request2.data[0])
             
             setFormulaSeleccionada({meta:e,array:request.data,supermeta:request2.data[0]})
            //  setTabletoshow(request.data)
        //     setFormView(request2.data)
        //     console.log('fuck react',form_views)
      
           }
            fetchData(e)
            window.scrollTo(0, 0)
    }

    const { t, i18n } = useTranslation('formulas');
  
  return (
    <Container>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} zeroMinWidth>
        {form_views.map((l) => {
                // {console.log(l)}
                var date = new Date(l.date);
                return (
                // <Item>
                <Grid item xs={4} zeroMinWidth>
                    <Item elevation={5}>
                            <Typography sx={{ fontSize: 25 }} color="text.secondary" gutterBottom>
                            {l.name}
                            </Typography>
                            <Typography variant="h5" component="div">
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            {t('Date: ')+date.getDate()+ "/"+(date.getMonth()+1)+"/"+date.getFullYear()}
                            </Typography>
                            {/* <Typography variant="body2">
                            well meaning and kindly.
                            <br />
                            {'"a benevolent smile"'}
                            </Typography> */}
                            <Button onClick={handleFormulaView.bind(this,l)} size="small">{t('View Formula')}</Button>
                 </Item>
                </Grid>
                )})}
        </Grid>
        
    </Container>
  )
}
