import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useTranslation } from 'react-i18next';


export default function TextAnimation() {

    const { t, i18n } = useTranslation("landing");
    //     useEffect(() => {
    //     console.log(i18n)
    // },[i18n])
  return (
    <Wrapper>{t("Create your product. We will handle regulations")}</Wrapper>
  )
}

const animation = keyframes`
    0% { opacity: 0; transform: translateX(-100px) ;filter:blur(5px)}
    50% { opacity: 1; transform: translateX(0);}
    75% { opacity: 1; transform: translateX(0);}
    100% { opacity: 0; transform: translateX(0) ;}
    /* skewX(10deg) */
`
const Wrapper = styled.span`
    display: inline-block;
    animation-name: ${animation};
    animation-duration: 10s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;

`
