import React, { useEffect, useRef,useState } from 'react'
import './how.css'
import img1 from './media/-------1@1x.svg'
import img2 from './media/------@2x.png'
import img3 from './media/------2@2x.svg'
import img4 from './media/Untitled design (10).png'
import img5 from './media/screenshot--129--1@1x.png'
import img6 from './media/----------3-1@2x.png'
import gsap from "gsap"
import { useIntersection } from "react-use"
import { motion, useAnimation, useInView, Variants } from "framer-motion";
import { useTranslation } from 'react-i18next';

export default function How() {
    const { t, i18n } = useTranslation("landing");
    const ref = useRef(null)
    const inView = useInView(ref, { once: true }) 
    const animation = useAnimation()
    
    useEffect(() => {
        inView?animation.start({ opacity: 1,marginTop: '100px', transition: { type: "tween", duration:2 }}):
        animation.start({opacity:0,marginTop:'300px', x:'-200px'})

        // console.log(ref)
        // console.log(inView)
    },[inView])
    


//   const sectionRef = useRef(null)

//   const intersection = useIntersection(sectionRef, {
//       root:null,
//       rootMargin: "0px",
//       threshold:0.3
//   })
//   const fadeIn = element => {
    
//       gsap.to(element,1 , {
//         duration: 2,
//           opacity: 1,
//           y: -60,
//           ease: "power2.in",
//           stagger: {
//               amount:0.3
//           }
//       })

      
//   }
//   const fadeOut = element => {
//       gsap.to(element,1 , {
//         duration: 2,
//           opacity: 0,
//           y: -20,
//           ease: "power2.out"
//       })
//   }
//   intersection && intersection.intersectionRatio < 0.3 ? fadeOut(".view") : fadeIn(".view")
  return (
    <motion.div animate = {animation} ref={ref}  className='view'>
        <h1 className='title' id='how'>{t("How does it work?")} </h1>
        <div className='overlap-group-container'>
             
            <div className='overlap-group-4'>
                <div className='for-creating-your-ne roboto-normal-black-20px'>
                    {t("To create your new formula, first choose the categories in which the product will be applied, we will manage the restrictions accordingly.")}
                </div>
                
                <img className='image' src={img1}/>
                
                <div className='text inter-medium-black-13px'>
                    <div className='flex-row-1'>
                        <div className='lips valign-text-middle'><b>Lips</b></div>
                        <div className='axillae valign-text-middle'><b>Axillae</b></div>
                        <div className='overlap-group2 inter-medium-black-13px'>
                            <div className='on-face-using-fingertips valign-text-middle'><b> On face fingertips</b></div>
                            <div className='fine-fragance valign-text-middle'><b>Fine Fragance</b></div>
                            <div className='face-mosturizer valign-text-middle'><b>Face Mosturizer</b></div>
                            <div className='body-lotion-using-hands valign-text-middle'><b>Body Lotion using hands</b></div>
                        </div>
                    </div>
                    <div className='flex-row-2'>
                        <div className='oral-and-lip-exposure valign-text-middle'> <b>Oral and lip exposure</b></div>
                        <div className='rinse-off-hair valign-text-middle'> <b>Rinse-off Hair</b></div>
                        <div className='leave-on-hair valign-text-middle'> <b>Leave-on Hair</b></div>
                        <div className='anogenital valign-text-middle'> <b>Anogenital</b></div>
                        <p className='body-and-hand-rinse-off valign-text-middle'> <b>Body and hand, rinse off</b></p>
                    </div>
                    <div className='overlap-group1'>
                        <p className='skin-contact-with-mi valign-text-middle'>
                            <span>
                                <span className='span0 inter-medium-black-13px'><b>Skin Contact with</b></span>
                                <span className='span1 inter-medium-black-13px'><b> minimal fragance transfer without UV exposure</b></span>
                            </span>
                        </p>
                        <div className='household-care valign-text-middle'> <b>Household care aerosol/spray </b></div>
                    </div>
                    <div className='skin-contact-container'>
                        <p className='skin-contact-with-mi-1 valign-text-middle'>
                            <span>
                                <span className='span0 inter-medium-black-13px'><b>Skin contact with </b></span>
                                <span className='span1 inter-medium-black-13px'><b>minimal fragance with UV exposure</b></span>
                            </span>
                        </p>
                        <div className='not-intendedminimal-skin-contact valign-text-middle'><b>Not intended/minimal skin contact</b></div>
                    </div>
                    <div className='overlap-group-2 inter-medium-black-13px'>
                        <div className='household-care valign-text-middle'><b>Household care excluding aerosol</b></div>
                        <div className='hand-cream valign-text-middle'><b>Hand Cream</b></div>
                        <div className='baby-creams valign-text-middle'><b>Baby Creams</b></div>
                    </div>
                </div>
                <div id='fader1'></div>
                <img className='image-1' src={img2} />
                <div className='ellipse-1'></div>
                <div className='number outfit-bold-astronaut-blue-60px' id='firstNumber'>1</div>
            </div>
            <div className='overlap-group-6'>
                {/* <img className='x2' src={img3}></img> */}
                {/* <h1 className='title'> How is it working? </h1> */}
            </div>
        </div>
        <div className='flex-row-3'>
            <div className='then-upload-you-ch roboto-normal-black-20px'>
                {t("Then upload your chromatogram or choose the necessary elements, we will do each calculation and suggestions for you.")}
            </div>
            <div className='overlap-group-7'>
                <div className='number-1 outfit-bold-astronaut-blue-60px' id='secondNumber'>2</div>
            </div>
        </div>
        <img className='image-2-1' src={img4}></img>
        <div id='fader2'></div>
        <div className='overlap-group-5'>
            <div className='ellipse-5-1'></div>
            <div className='number outfit-bold-astronaut-blue-60px' id='thirdNumber'>3</div>
            <div className='finally-save-your-f roboto-normal-black-20px'>{t("Finally, save your formula and print your certificate (now in English and Spanish). Everything will be waiting for you if you need further adjustments.")}</div>
            <img className='screenshot-129-1' src={img5}></img>
            <img className='x3-1' src={img6}></img>
        </div>
    </motion.div >
  )
}
