import React, { useEffect } from 'react'
import { Form, Table } from 'react-bootstrap'
import { AiFillDelete } from 'react-icons/ai'
import { useTranslation } from 'react-i18next';

export const SelectedProdTable = ({ultraselect,setUltraselect,ultraselectcompounds,setUltraselectcompounds,removeElement,removeElement2,mineElements,comerElements,removeElement4,removeElement5,setMineElements,setComerElements}) => {
  const { t, i18n } = useTranslation("newformulas");
    const handlePercent=(e,index)=>{
        let obj=ultraselect
        obj[index].percent=e.target.value
        setUltraselect([...obj])
    }
    const handleIntensity=(e,index)=>{
        let obj=ultraselect
        obj[index].intensity=e.target.value
        setUltraselect([...obj])
    }
    const handlePercent2=(e,index)=>{
        let obj=ultraselectcompounds
        obj[index].percent=e.target.value
        setUltraselectcompounds([...obj])
    }
    const handleIntensity2=(e,index)=>{
        let obj=ultraselectcompounds
        obj[index].intensity=e.target.value
        setUltraselectcompounds([...obj])
    }
    const handlePercent4=(e,index)=>{
      let obj=mineElements
      obj[index].percent=e.target.value
      setMineElements([...obj])
  }
  const handleIntensity4=(e,index)=>{
      let obj=mineElements
      obj[index].intensity=e.target.value
      setMineElements([...obj])
  }
  const handlePercent5=(e,index)=>{
    let obj=comerElements
    obj[index].percent=e.target.value
    setComerElements([...obj])
}
const handleIntensity5=(e,index)=>{
    let obj=comerElements
    obj[index].intensity=e.target.value
    setComerElements([...obj])
}
  // useEffect(()=>{console.log('comer',comerElements)},[comerElements])
  return (
    <div><Table bordered hover>
    <thead>
      <tr className='table-primary' style={{textAlign:'center'}}>
        <th>{t("NAME")}</th>
        <th>CAS</th>
        <th>{t("PERCENTAGE")}</th>
        <th>{t("PURITY")}</th>
        <th className='table-danger border border-danger'>{t("DELETE")}</th>
      </tr>
    </thead>
    
    <tbody className='table-striped'>
    {ultraselect.map((element,index)=>{
          return(
            <tr className='table-primary'  key={index} style={{textAlign:'center'}}>
              <th style={{margin:'0px'}}>{element.ifraElement.name_ifra}</th>
              <th style={{margin:'0px'}}>{element.ifraElement.cas_number}</th>
              <th>
          <Form.Control size='sg' className={'text-white'} value={element.percent} onChange={(e)=>{handlePercent(e,index)}} style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>
          </th>
        
              
          <th>
          <Form.Control size='sg' className={'text-white'} value={element.intensity} onChange={(e)=>{handleIntensity(e,index)}} style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>
          </th>
          <th className='table-danger border border-danger' onClick={()=>{removeElement(index)}} style={{cursor:'pointer'}}><AiFillDelete size='1.3em'/></th>
            </tr>)
  
      })}
      
      
     
      
    </tbody>
    
    
  
  
      <tbody className='table-striped'>{ultraselectcompounds.map((element,index)=>{
          return(
            <tr className='table-secondary' key={index} style={{textAlign:'center'}} >
              <th className='text-black'>{element.ifraElement.ncs_name}</th>
              <th className='text-black'>{element.ifraElement.CAS} {element.ifraElement.other_CAS}</th>
              <th className='text-black'>
          <Form.Control size='sg'  className='text-black' backgroundColor='secondary' value={element.percent} onChange={(e)=>{handlePercent2(e,index)}} style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>
          </th>
        
              
          <th >
          <Form.Control size='sg' className='text-black' value={element.intensity} onChange={(e)=>{handleIntensity2(e,index)}} style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>
          </th>
          <th className='table-danger border border-danger' onClick={()=>{removeElement2(index)}} style={{cursor:'pointer'}}><AiFillDelete size='1.3em'/></th>
            </tr>)
  
      })}</tbody>

<tbody>{mineElements.map((element,index)=>{
          return(
            <tr key={index} style={{textAlign:'center'}} >
              <th className='text-black' style={{backgroundColor:'rgb(250, 219, 198)'}}>{element.ifraElement.name}</th>
              <th className='text-black' style={{background:'rgb(250, 219, 198)'}}>{''}</th>
              <th className='text-black' style={{background:'rgb(250, 219, 198)'}}>
          <Form.Control size='sg'  className='text-black'  value={element.percent} onChange={(e)=>{handlePercent4(e,index)}} style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>
          </th>
        
              
          <th style={{background:'rgb(250, 219, 198)'}}>
          <Form.Control size='sg' className='text-black' value={element.intensity} onChange={(e)=>{handleIntensity4(e,index)}} style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>
          </th>
          <th className='table-danger border border-danger' onClick={()=>{removeElement4(index)}} style={{cursor:'pointer'}}><AiFillDelete size='1.3em'/></th>
            </tr>)
  
      })}</tbody>


<tbody>{comerElements.map((element,index)=>{
          const colorr='rgb(194, 255, 202)'
          return(
            <tr className='text-black' key={index} style={{textAlign:'center',background:colorr}} >
              <th className='text-black' >{element.name}</th>
              <th className='text-black' >{element.cas}</th>
              <th className='text-black' >
          <Form.Control size='sg'  className='text-black'  value={element.percent} onChange={(e)=>{handlePercent5(e,index)}} style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>
          </th>
        
              
          <th style={{background:colorr}}>
          <Form.Control size='sg' className='text-black' value={element.intensity} onChange={(e)=>{handleIntensity5(e,index)}} style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>
          </th>
          <th className='table-danger border border-danger' onClick={()=>{removeElement5(index)}} style={{cursor:'pointer'}}><AiFillDelete size='1.3em'/></th>
            </tr>)
  
      })}</tbody>
  
  
  
    
  </Table></div>
  )
}
