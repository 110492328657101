import React from 'react'
import { Form, Table } from 'react-bootstrap'
import { AiFillDelete } from 'react-icons/ai'
import { useTranslation } from 'react-i18next';

export const RestTable = ({randArray,removeElement,setRandArray}) => {
  const { t, i18n } = useTranslation("newformulas");
    const handlePercent=(e,index)=>{
        let obj=randArray
        obj[index].percent=e.target.value
        setRandArray([...obj])
    }
    const handleIntensity=(e,index)=>{
        let obj=randArray
        obj[index].intensity=e.target.value
        setRandArray([...obj])
    }
    const handleCAS=(e,index)=>{
      let obj=randArray
      obj[index].cas=e.target.value
      setRandArray([...obj])
  }
  return (
    <div><Table striped bordered hover variant='dark'>
    <thead>
        <tr style={{textAlign:'center'}}>
          <th>{t("NAME")}</th>
          <th>{t("CAS")}</th>
          <th>{t("PERCENTAGE")}</th>
          <th>{t("PURITY")}</th>
          <th className='table-danger border border-danger'>{t("DELETE")}</th>
        </tr>
      </thead>
    
    
      
      <tbody>{randArray.map((element,index)=>{
            return(
              <tr key={index} style={{textAlign:'center'}} >
                <th >{element.name}</th>
                <th >
            <Form.Control size='sg' className={'text-white'}  value={element.cas} onChange={(e)=>{handleCAS(e,index)}}  style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>
            </th>
                
                <th >
            <Form.Control size='sg' className={'text-white'}  value={element.percent} onChange={(e)=>{handlePercent(e,index)}}  style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>
            </th>
          
                
            <th >
            <Form.Control size='sg' className={'text-white'} value={element.intensity} onChange={(e)=>{handleIntensity(e,index)}} style={{backgroundColor:'transparent',border:'none',boxShadow:'none',margin:'0px',textAlign:'center'}}/>
            </th>
            <th className='table-danger border border-danger' onClick={()=>{removeElement(index)}} style={{cursor:'pointer'}}><AiFillDelete size='1.3em'/></th> 
              </tr>)
    
        })}
        
        
        </tbody></Table></div>
  )
}
