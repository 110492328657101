import React, { useEffect, useState } from 'react'
//import '../assets/css/mdb.dark.min.css'
//import '../assets/css/bootstrap.min.css'
import { Navbar,Nav,Container,NavDropdown} from 'react-bootstrap'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import { useCookies } from 'react-cookie'
import i18n from '../../i18n'
import { useTranslation } from 'react-i18next';
import logo from '../../assets/media/innormabig.png'
import './logo.css'



const navbar = {backgroundColor: '#0a3a61'}


export const NavBaro = () => {
  
  const { t, i18n } = useTranslation("tags");

  const urlBool=(location)=>{
    if (location=='/formulas' || location=='/formulas/'){
      return true
    }
    else{
      return false
    }
    }
  
  
  let location = useLocation() 
  const [hover1,setHover1]=useState('white')
  const [hover2,setHover2]=useState('white')
  const [superhover1,setsuperHover1]=useState((urlBool(location.pathname)? 'secondary' : 'white'))
  const [superhover2,setsuperHover2]=useState((!urlBool(location.pathname)? 'secondary':'white'))
  const [hover3,setHover3]=useState('white')
  useEffect(()=>{
    //console.log('cambio', location.pathname)
    if (!urlBool(location.pathname)){
      setsuperHover1('white')
      setsuperHover2('secondary')
    }
    else if(urlBool(location.pathname)){
      setsuperHover2('white')
      setsuperHover1('secondary')

    }
  },[location])
  useEffect(()=>{
    setHover1(superhover1)},[superhover1])
  useEffect(()=>{
    setHover2(superhover2)
  },[superhover2])
  // const [token,setToken,removeToken]=useCookies(['access_token','refresh_token','username'])
  const navigate = useNavigate(); 
  const removeLocal=()=>{
    localStorage.removeItem("updateBoolStorage")
    localStorage.removeItem("restStorage")
    localStorage.removeItem("titleStorage")
    localStorage.removeItem("transStorage")
    localStorage.removeItem("manualCompsStorage")
    localStorage.removeItem("complexStorage")
    localStorage.removeItem("constiStorage")
    localStorage.removeItem("utilStorage")
    navigate('/newformula/')
    window.location.reload(false);
    // setLang('en')
    
    setsuperHover1('white')
    setsuperHover2('secondary')
    
  }
  const logOuter=()=>{
    localStorage.removeItem(['refresh_token'])
    localStorage.removeItem(['username'])
    localStorage.removeItem(['access_token'])
    localStorage.removeItem(['company_name'])

    localStorage.removeItem("updateBoolStorage")
    localStorage.removeItem("restStorage")
    localStorage.removeItem("titleStorage")
    localStorage.removeItem("transStorage")
    localStorage.removeItem("manualCompsStorage")
    localStorage.removeItem("complexStorage")
    localStorage.removeItem("constiStorage")
    localStorage.removeItem("utilStorage")

  }

  const [lang,setLang]=useState((window.localStorage.getItem('lang') ? JSON.parse(window.localStorage.getItem('lang')) : 'en'))
  useEffect(()=>{
    i18n.changeLanguage(lang)
    window.localStorage.setItem('lang',JSON.stringify(lang))
  },[lang])

  return (<div><Navbar style={navbar} expand="lg" fixed='top' variant='dark'>

  <Container>
    <Navbar.Brand as={Link} to='/formulas'>{<img  width="120" height="30" className="d-inline-block poisition-relative" src={logo} alt="Logo"></img>}</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link as={Link} onMouseEnter={()=>(setHover1((superhover1=='secondary'?'white':'secondary')))} onMouseLeave={()=>(setHover1((superhover1=='secondary'?'secondary':'white')))} className={`text-${hover1}`} to='/formulas'>{t("My Formulas")}</Nav.Link>
        <Nav.Link as ={Link} onMouseEnter={()=>(setHover2((superhover2=='secondary'?'white':'secondary')))} onMouseLeave={()=>(setHover2((superhover2=='secondary'?'secondary':'white')))} className={`text-${hover2}`} to='/newformula'>{t("Workbench")}</Nav.Link>
        {/* modificar */}
            {/* <Button onClick={() => i18n.changeLanguage('es')}>de</Button>
    <Button onClick={() => i18n.changeLanguage('en')}>en</Button> */}
      </Nav>
      <Nav>
      <Nav.Link as ={Link} onMouseEnter={()=>(setHover2('secondary'))} onClick={()=>{removeLocal()}} onMouseLeave={()=>(setHover2('white'))} className={`text-secondary`} to='/newformula'>{t("Create New Formula")}</Nav.Link>
      <NavDropdown onMouseEnter={()=>(setHover3('secondary'))} onMouseLeave={()=>(setHover3('white'))} className={`text-${hover3}`} title={localStorage.getItem('username')} id="navbarScrollingDropdown">
          <NavDropdown.Item onClick={()=>setLang('es')}>{t("Spanish")}</NavDropdown.Item>
          <NavDropdown.Item onClick={()=>setLang('en')}>{t("English")}</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={logOuter} as={Link} to='/'>
            Log Out
          </NavDropdown.Item>
        </NavDropdown>
        </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
</div>
    

  )
}
/*
    <div>
<nav classNameNameNameName="navbar navbar-expand-lg navbar-dark bg-primary">

<div classNameNameNameName="container-fluid">

<button
classNameNameNameName="navbar-toggler"
type="button"
data-mdb-toggle="collapse"
data-mdb-target="#navbarCenteredExample"
aria-controls="navbarCenteredExample"
aria-expanded="false"
aria-label="Toggle navigation"
>
<i classNameNameNameName="fas fa-bars"></i>
</button>


<div
classNameNameNameName="collapse navbar-collapse justify-content-center"
id="navbarCenteredExample"
>

<ul classNameNameNameName="navbar-nav mb-2 mb-lg-0">

<li classNameNameNameName="nav-item">
    <a classNameNameNameName="nav-link" href="#">Link</a>
</li>
<li classNameNameNameName="nav-item">
    <a classNameNameNameName="nav-link" href="#">Link</a>
</li>
<li classNameNameNameName="nav-item">
    <a classNameNameNameName="nav-link" href="#">Link</a>
</li>



</ul>

</div>

</div>

</nav></div>


*/

/*<Navbar bg='dark' variant='primary' fixed='top' expand='lg'>
      <Container>
      
      
      
      <Nav.Link as={Link} to='/formulas'> formulas
      </Nav.Link>
      <Nav.Link as ={Link} to='/newformula'> new formula
      </Nav.Link>



      
      
      
      </Container>
    </Navbar>*/


